<div class="coatings-container" *ngFor="let coatingCatalog of (coatings$ | async); let i = index;">
    <p class="col s10 offset-s1 subline" style="margin-top: 6%; ">
        {{ ((translate.onLangChange | async)?.lang || translate.currentLang) | catalogName: coatingCatalog?.key }}

        <mat-icon *ngIf="(coatingTooltip$ | async)[i]" class="coating-tint-info subline" matTooltip="{{ 'tooltips.viewer.duravision_mirror-info' | translate }}">info</mat-icon>
    </p>
    <div class="col s10 offset-s1">
        <div style="margin-top: 1%;" class="col s12 lenseoptions">
            <div class="col s6 m6 l6 xl4 filter-item style boxshape" *ngFor="let lens of coatingCatalog?.value">
                <app-coating-host [coating]="lens" [selectedFrame$]="selectedFrame$" [selectedCoating$]="selectedCoating$" (onCoatingClick)="selectCoating($event)"></app-coating-host>
            </div>
        </div>
    </div>
</div>