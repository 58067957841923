import { AfterViewInit, ElementRef, Component, EventEmitter, OnDestroy, Output, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Subject, Subscription } from "rxjs";
import { debounceTime, map, startWith } from "rxjs/operators";

@Component({
    selector: "vis-frame-gallery-search",
    templateUrl: "./frame-gallery-search.component.html",
    styleUrls: ["./frame-gallery-search.component.scss"],
})
export class FrameGallerySearchComponent implements OnDestroy, AfterViewInit {
    @ViewChild("searchInput") private searchInput: ElementRef<HTMLInputElement>;

    @Output() public searchTerm: EventEmitter<string> = new EventEmitter();

    private inputSubscription: Subscription;

    private debouncer: Subject<string> = new Subject();

    public searchValue: string;

    public focused: boolean;

    private sessionId: string;

    @Output()
    public onFocus: EventEmitter<boolean> = new EventEmitter();

    constructor(
        private route: ActivatedRoute,
    ){
        this.sessionId = this.route.snapshot.params.sessionId;
        this.searchValue = this.getPersistedSearch().get(this.sessionId) ?? "";
    }

    public ngAfterViewInit() {
        this.inputSubscription = this.debouncer
            .pipe(
                debounceTime(500),
                startWith(this.getPersistedSearch().get(this.sessionId) ?? "")
            )
            .subscribe((s) => {
                this.searchTerm.emit(s.toLocaleLowerCase());
                this.searchValue = s;
                let persistedSearch = this.getPersistedSearch().set(this.sessionId, s);
                this.setPersistedSearch(persistedSearch);
            });
    }

    public ngOnDestroy() {
        if (this.inputSubscription) {
            this.inputSubscription.unsubscribe();
        }
    }

    public onSearch(searchterm: string) {
        this.debouncer.next(searchterm);
    }

    public openSearch() {
        this.focused = true;
        this.onFocus.emit(true);
        requestAnimationFrame(() => {
            this.searchInput.nativeElement.value = this.searchValue;
            this.searchInput.nativeElement.focus();
        });
    }

    public blurSearchbar() {
        this.focused = false;
        this.onFocus.emit(false);
    }

    public clearSearch(event: Event) {
        event.preventDefault();
        event.stopPropagation();
        this.searchTerm.emit("");
        this.searchValue = "";
        let persistedSearch = this.getPersistedSearch();
        persistedSearch.delete(this.sessionId);
        this.setPersistedSearch(persistedSearch);
        this.searchInput.nativeElement.blur();
    }

    public setPersistedSearch(persistedSearch: Map<string,string>) {
        localStorage.setItem('searchTerm', JSON.stringify(persistedSearch, function(key, value) {
            const originalObject = this[key];
            if(originalObject instanceof Map) {
                return {
                    dataType: 'Map',
                    value: [...originalObject],
                };
            } else {
                return value;
            }
        }));
    }

    public getPersistedSearch(): Map<string, string> {
        let searchMap =  JSON.parse(
            localStorage.getItem('searchTerm'),
            function(key, value) {
                if(typeof value ==='object' && value !==null) {
                    if(value.dataType === 'Map') {
                        return new Map(value.value);
                    }
                }
                return value;
            }
        ) as Map<string, string>;

        if (!(searchMap instanceof Map)) {
            searchMap = new Map();
        }
        return searchMap;
    }
}