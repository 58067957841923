import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from 'visauto-auth';

import { NotFoundComponent } from 'utilities';
import { LandingPageComponent } from './pages/landing/landing.page.component';
import { NoAvatarGuard } from './vto/guards/no-avatar.guard';
import { FAQPageComponent } from './pages/FAQ/faq.page.component';
import { UnsubscribePageComponent } from './pages/unsubscribe/unsubscribe.page.component';
import { RecommendationLandingPageComponent } from './pages/recommendation-landing/recommendation-landing.page.component';


const routes: Routes = [
    { path: '', redirectTo: 'landing', pathMatch: 'full' },
    { path: 'recommendations', pathMatch: 'full', component: RecommendationLandingPageComponent },
    { path: 'landing', pathMatch: 'full', component: LandingPageComponent },
    { path: 'FAQ', pathMatch: 'full', component: FAQPageComponent },
    { path: 'unsubscribe', pathMatch: 'full', component: UnsubscribePageComponent },
    { path: '404-not-found', pathMatch: 'full', component: NotFoundComponent }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule { }
