import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { OAuthService } from 'angular-oauth2-oidc';
import { PrivacyDialogComponent } from '../../dialogs/privacy/privacy.dialog.component';
import { GoogleTagManagerService, GTMCustomEvents } from '../../vto/services/gtm.service';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'vis-landing-page',
    templateUrl: './landing.page.component.html',
    styleUrls: ['./landing.page.component.scss']
})
export class LandingPageComponent implements OnInit, AfterViewInit {

    private readonly PRIVACY_FLAG = 'vis-privacy';

    constructor(private oAuth: OAuthService, private gtm: GoogleTagManagerService, private dialogHost: MatDialog) { }

    public get isAuthenticated() {
        return this.oAuth.hasValidIdToken();
    }

    ngOnInit(): void {
        // this.debug.logInformation('accessing landing page...');
        this.gtm.push();
    }

    ngAfterViewInit() {
        const flag = this.getPrivacyFlag();
        if (!flag) {
            if(window.innerWidth > 639 && window.innerWidth <= 979 && window.innerWidth > window.innerHeight) {
                this.dialogHost.open(PrivacyDialogComponent, { 
                    width: '80vw',
                    maxHeight: "90vh", 
                    autoFocus: false 
                });
            } else if (window.innerWidth > 601) {
                this.dialogHost.open(PrivacyDialogComponent, { 
                    width: '50vw',
                    maxHeight: "90vh", 
                    autoFocus: false 
                });
            } else {
                this.dialogHost.open(PrivacyDialogComponent, { 
                    width: '99vw', 
                    height: '85vh', 
                    autoFocus: false 
                });
            }
            this.setPrivacyFlag();
        }
    }

    onLogin() {
        this.gtm.pushEvent(GTMCustomEvents.landing_login);
        this.oAuth.initLoginFlow();
    }

    private getPrivacyFlag(): boolean {
        let flag = false;
        if (localStorage.getItem(this.PRIVACY_FLAG) != null || localStorage.getItem(this.PRIVACY_FLAG) != undefined) {
            flag = localStorage.getItem(this.PRIVACY_FLAG) == 'true' ? true : false
        }

        return flag;
    }

    private setPrivacyFlag() {
        localStorage.setItem(this.PRIVACY_FLAG, 'true');
    }

}
