<div class="tints-container" *ngFor="let name of (tintCatalogNames$ | async)">
    <p class="col s10 offset-s1 subline" style=" margin-top: 6%; ">
        {{ ((translate.onLangChange | async)?.lang || translate.currentLang) | catalogName: name }}</p>
    <div class="col s10 offset-s1">
        <div style="margin-top: 1%;" class="col s12 lenseoptions">
            <div class="col s6 m6 l6 xl4 filter-item style boxshape" *ngFor="let tintObj of (tints$ | async)[name]">
                <app-tint-host [tint]="tintObj" [selectedFrame$]="selectedFrame$" [selectedCoating$]="selectedCoating$" [selectedTint$]="selectedTint$" (onTintClick)="selectTints($event)"></app-tint-host>
            </div>
        </div>
    </div>
</div>