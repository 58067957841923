import { Component, Inject } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { ConsumerAppEnvironment as environment } from 'visenvironment';

@Component({
    selector: 'vcld-snackbar',
    templateUrl: './snackbar.component.html',
    styleUrls: ['./snackbar.component.scss']
})

export class SnackbarComponent {
    constructor(
        private _snackRef: MatSnackBarRef<SnackbarComponent>,
        @Inject(MAT_SNACK_BAR_DATA) public data: any
    ){}

    public dismiss() {
        this._snackRef.dismiss();
    }

    public openSupport() {
        window.open(environment.contact.form_stack, "_blank", "location=no");
    }

}