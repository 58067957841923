import { Injectable } from '@angular/core';
import { ConsumerAppEnvironment as environment } from 'visenvironment';
import {
    HttpEvent, HttpInterceptor, HttpHandler, HttpRequest
} from '@angular/common/http';
import { OAuthService } from 'angular-oauth2-oidc';
import { of } from 'rxjs';
import { DebugService } from '../services/debug.service';
import { Router } from '@angular/router';
import { LoggerService, LogSeverity } from '../services/logger.service';

@Injectable({
    providedIn: 'root'
})
export class AutoLogoutInterceptor implements HttpInterceptor {

    // Check if the url is protected and prevention is needed.
    private readonly esbCheck: RegExp = /^ *https?:\/\/(esb-dev|esb-stage|esb)\.zeiss\.com/;

    private readonly unsubscribeUrl = environment.connectivity.unsubscribeEndpoint;

    private readonly dmtLinkUrl = environment.connectivity.dmt_link_used;

    constructor(private _oAuth: OAuthService, private _log: LoggerService, private _router: Router) { }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        const isESB = this.esbCheck.test(req.url);
        const isUnsubscribe = req.url.startsWith(this.unsubscribeUrl);
        const isDMTLink = req.url.startsWith(this.dmtLinkUrl);
        this._log.log(LogSeverity.SECURITY, `Authentication interception of ${req.url}`);
        // return to landing page if user is not authenticated and tries to access a VISUCLOUD Resource.
        if (isESB && !isUnsubscribe && !isDMTLink && !this._oAuth.hasValidIdToken()) {
            this._log.log(LogSeverity.SECURITY, `Authentication interception is missing a token!`);
            this._router.navigate(['/landing']);
            return of(null);
        }

        return next.handle(req);
    }
}