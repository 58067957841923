import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'formatPrice'
})
export class FormatPricePipe implements PipeTransform {
  constructor(private translate: TranslateService) { }
  transform(value:number, checkForNull?: boolean): string {

    // isNaN(null) check returns false. So, adding additional check for null value.
    if (checkForNull) {
      if (value === null || value === 0) return "-";
    }

    if(isNaN(value))
        return value?.toString();
    return new Intl.NumberFormat(navigator.language).format(value);
  }
}
