<div class="dialog-header">
        <p mat-dialog-title class="bold">{{"components.buttons.license" | translate }}</p>
        <button vcldAutomationId="consr_vis-license-page_licence-close_btn" mat-icon-button class="close-btn" [mat-dialog-close]="true">
            <mat-icon>close</mat-icon>
        </button>
    </div>
<mat-dialog-content>
    <mat-accordion multi [@.disabled]="disableAnimation" class="license-desc">
        <mat-expansion-panel vcldAutomationId="consr_vis-license-page_licence-expand-licence_btn" *ngFor="let license of licenses; let i = index;"
            (opened)="openPanel('license-' + i)" (closed)="closePanel('license-' + i)" [id]="'license-' + i">
            <mat-expansion-panel-header>
                <mat-panel-title class="bold text" [ngClass]="getCSS('faq-' + i)">{{ license.name }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <p>{{ 'components.dialogs.licensing.type' | translate}}: {{license.licenseType}}</p>
            <p>{{ 'components.dialogs.licensing.link' | translate}}: <a mat-button target="_blank" [href]="license.link | slice:4">{{license.link}}</a></p>
            <p>{{ 'components.dialogs.licensing.author' | translate}}: {{license.author}}</p>
        </mat-expansion-panel>
    </mat-accordion>
</mat-dialog-content>