
import {AuthConfig} from 'angular-oauth2-oidc';
import { ConsumerAppEnvironment as environment } from 'visenvironment';

export const authConfig: AuthConfig = {
    clientId: environment.authentication.clientId,
    redirectUri: window.location.origin,
    responseType: environment.authentication.responseType,
    requestAccessToken: environment.authentication.requestAccessToken,
    scope: environment.authentication.scope,
    dummyClientSecret: environment.authentication.dummyClientSecret || null,
    issuer: environment.authentication.issuer,
    skipIssuerCheck: environment.authentication.skipIssuerCheck,
    strictDiscoveryDocumentValidation: environment.authentication.strictDiscoveryDocumentValidation,
    showDebugInformation: environment.authentication.showDebugInformation,
    waitForTokenInMsec: 100000,
};

export const discoverDocumentOverride = environment.authentication.overrideDiscoveryDocumentUri;

export const discoverDocumentOverrideSignUp = environment.authentication.overrideDiscoveryDocumentUriSignUp;
