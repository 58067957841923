import { FavoriteFrame } from './../../models/avatarcreationsession';
import { AfterContentInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of, ReplaySubject } from 'rxjs';
import { finalize, first, map, mergeMap, skip, startWith, tap } from 'rxjs/operators';
import { ColorVariant, Frame, FrameAvailability } from '../../models/frame.model';
import { ConsumerAppEnvironment as environment } from 'visenvironment';
// import { FrameService } from '../../services/frame.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DebugService } from '../../../services/debug.service';
import { FeatureFlag } from '../../models/ecp-settings.model';
import { CONSOLE_STYLE as cs } from '../../../configs/constant.flags';
import { MatTooltip, MAT_TOOLTIP_SCROLL_STRATEGY } from '@angular/material/tooltip';
import { _getOptionScrollPosition } from '@angular/material/core';
import { ScrollService } from '../../services/scroll.service';
import { CoatingObj, TintObj } from "../../../configs/lensSettings.mock";
import { FormatPricePipe } from '../../pipes/format-price.pipe';
import { DomSanitizer } from '@angular/platform-browser';
import { FrameService } from '../../services/frame.service';
import { SessionService } from '../../services/session.service';
import { DataService } from '../../services/data.service';
import { TintsCoatingsService } from '../../services/tints-coatings.service';


@Component({
    selector: 'vis-gallery-frame-host',
    templateUrl: './gallery-frame-host.component.html',
    styleUrls: ['./gallery-frame-host.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GalleryFrameHostComponent implements OnInit {
    @Input()
    public frame: Frame = null;

    @Input()
    public pricingFlag: boolean;

    @Input()
    public materialFlag: boolean;

    @Input()
    public colorFlag: boolean;

    @Input()
    public modelNameFlag: boolean;

    @Input()
    public dmtFlag: boolean = false;

    @Input()
    public frameCompareList: Array<FavoriteFrame> = [];

    @Input('favoriteSelectedObservable')
    public favoritesSelected$;

    @Input('favoritedFrameIdsObservable')
    public favoritedFrames$;

    @Input('sessionId')
    public sessionId: string;

    @Input()
    public changeTrigger$: ReplaySubject<string>;

    @Input()
    public mode: 'favorite' | 'optician';

    @Input()
    public coating?: CoatingObj = null;

    @Input()
    public tint?: TintObj = null;

    @Input('scrollToPosition')
    public scrollToPosition: string;

    @Input('endIndex')
    public endIndex: string;

    @Output()
    public onChange: EventEmitter<string> = new EventEmitter();

    @Output()
    public onCompareCheckboxChange: EventEmitter<any> = new EventEmitter();

    @Input()
    public isList?: boolean = true;

    @Input()
    public showCompare?: boolean = true;
    
    public frameIdForImage: string;
    public get screenWidth() {
        return window.innerWidth;
    }

    public get isSmallScreen()
    {
        return this.screenWidth <= 640;
    }
    private _frameIdForImage$: BehaviorSubject<string>;

    private _availabilityStatus$: Observable<FrameAvailability | string>;
    public availableAgainFrom$: Observable<Date>;

    public isFrameAvailable$: Observable<boolean>;
    public isFrameTemporaryUnavailable$: Observable<boolean>;
    public isFramePermanentlyUnavailable$: Observable<boolean>;

    public frameImage$: ReplaySubject<string> = new ReplaySubject(1);

    private initialFrameId: string;

    public flipped: boolean = false;

    public translationObjName: string = 'localization';
    
    public isFavoritedFrame$: Observable<boolean>;
    public tintThumbnail;
    public coatingThumbnail;
    public _favClick$ :BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    
    constructor(
        private debug: DebugService,
        private cd: ChangeDetectorRef,
        private router: Router,
        private _scroll: ScrollService,
        private formatPrice: FormatPricePipe,
        private _sanitizer: DomSanitizer,
        private _frames:FrameService,
        public _session: SessionService,
        private _datacache:DataService,
        public _tintsCoatings:TintsCoatingsService
    ) {
     }

    public async ngOnInit() {

        this.frameIdForImage = this.frame.id;
        this._frameIdForImage$ = new BehaviorSubject(this.frame.id);
        this.frameImage$.next(await this.frameImage);

        this.isFavoritedFrame$ = combineLatest([
            this._favClick$,
            this._frameIdForImage$,
            this._session.selectedSession$
        ]).pipe(
            map(([favoriteClick,frameId, session]) => {
                if (frameId !== this.frame.id) {
                    return session.favoritedFrames ? session.favoritedFrames.findIndex(favt => favt.frameId === frameId && !favt.tintId && !favt.coatingId) > -1 : false;
                } else {
                    return session.favoritedFrames ? session.favoritedFrames.findIndex(favt => favt.frameId === this.frame.id && favt.tintId == this.frame.tint?.catalogCode && favt.coatingId == this.frame.coating?.catalogCode) > -1 : false;
                }
            }),
            tap(_=> {
                this.cd.detectChanges();
            })
        );        
        
        const sortedVariants: ColorVariant[] = [];

        this._availabilityStatus$ = combineLatest([this._frameIdForImage$, this._frames.allFrames$]).pipe(
            map(([id, frames]) => frames.filter(f => f.id === id).pop().availabilityStatus)
        );

        this.availableAgainFrom$ = combineLatest([this._frameIdForImage$, this._frames.allFrames$]).pipe(
            map(([id, frames]) => frames.filter(f => f.id === id).pop().availableAgainFrom)
        );

        this.isFrameAvailable$ = this._availabilityStatus$.pipe(
            map(status => parseInt(FrameAvailability[status]) == FrameAvailability.AVAILABLE)
        );

        this.isFramePermanentlyUnavailable$ = this._availabilityStatus$.pipe(
            map(status => parseInt(FrameAvailability[status]) == FrameAvailability.PERMANENTLY_NOT_AVAILABLE)
        );

        this.isFrameTemporaryUnavailable$ = this._availabilityStatus$.pipe(
            map(status => parseInt(FrameAvailability[status]) == FrameAvailability.TEMPORARY_NOT_AVAILABLE)
        );

        this.frame.variants = [...sortedVariants, ...this.frame.variants];
    }

    ngOnChanges() {
        if (this.tint?.thumbnail) this.tintThumbnail = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/svg+xml;base64, ' + this.tint.thumbnail);
        if (this.coating?.thumbnail) this.coatingThumbnail = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/svg+xml;base64, ' + this.coating.thumbnail);
    }

    public get isAvailable(): boolean {
        if (this.mode == 'optician') return true;
        return this.frame.available;
    }

    public getFrameImage(frameVariantId) {
        return this._frames.getFrameThumbnail(frameVariantId);
    }

    public get frameImage() {
        this.debug.log(`%c[Rapid] %cupdating frame thumbnail with id %c${this.frameIdForImage}`, cs.prefix, cs.clear, cs.info);
        return this._frames.getFrameThumbnail(this.frameIdForImage);
    }

    public favoriteSelectedFrame(frame: Frame) {
        const subscriber = this._session.selectedSession$.pipe(
            first(),
            mergeMap(session => {
                const frameId = frame.id != this.frameIdForImage ? this.frameIdForImage : frame.id;
                const coatingId = frame.id != this.frameIdForImage ? null : frame.coating?.catalogCode;
                const tintId = frame.id != this.frameIdForImage ? null : frame.tint?.catalogCode;

                const index = session.favoritedFrames.findIndex(e => e.frameId === frameId && e.coatingId == coatingId && e.tintId == tintId);
                const favFrameListIndex=this._frames.listFavFramesSession.findIndex(f=>f.frameId === frameId && f.coatingId == coatingId && f.tintId == tintId);
                let favorites;
                const favtFrame = { frameId: frameId, coatingId: null, tintId: null, lastUpdate: new Date(Date.now()) } as FavoriteFrame;               
                if (index === -1) {
                    this._frames.listFavFramesSession= [...this._frames.listFavFramesSession,favtFrame];
                    favorites = [...session.favoritedFrames,... this._frames.listFavFramesSession];
                    if ((this.initialFrameId !== undefined) && (this.frameIdForImage != this.initialFrameId)) {
                        this.toggleFrameToInitial(this.initialFrameId);
                    }
                } else { 
                    
                    session.favoritedFrames.splice(index, 1);  
                    if(favFrameListIndex !=-1)this._frames.listFavFramesSession?.splice(favFrameListIndex,1);              
                    favorites = [...session.favoritedFrames,... this._frames.listFavFramesSession];               
                    if (favorites.findIndex(e => e.frameId === this.initialFrameId) !== -1) {
                        this.toggleFrameToInitial(this.initialFrameId);
                    }
                }
                let listFavFramesSession=JSON.parse(JSON.stringify(this._frames.listFavFramesSession));
                return this._frames.updateSessionFavorites(favorites,session.id, listFavFramesSession);
            }),
            finalize(() =>{
                this._favClick$.next(true);
                subscriber.unsubscribe()
            }
            )
        ).subscribe();
    }

    private async toggleFrameToInitial(frameId: string) {
        this.frameImage$.next(await this.getFrameImage(frameId));
        this.frameIdForImage = frameId;
        this._frameIdForImage$.next(frameId);
        this.debug.log(`%c[FrameHost]%c reset frame to initial after %cFAVORITE_SELECTED_FRAME %cwith frameId %c${frameId}`, cs.prefix, cs.clear, cs.info, cs.clear, cs.success);
        this.onChange.emit();
        this.cd.detectChanges();
    }

    // Checks if a given frame ID is selected for comparison
    public isSelected(frame: Frame) {
        if (this._frameIdForImage$.getValue() !== this.frame.id) {
            frame = this.getDefaultFrameVariantObject();
        }
       
        return this.frameCompareList.find(x => x.frameId === frame.id && x.coatingId == frame.coating?.catalogCode && x.tintId == frame.tint?.catalogCode);
    }

    public isCompareDisabled(frame: Frame): boolean {
        if (this._frameIdForImage$.getValue() !== frame.id) {
            const frameObj = this.getDefaultFrameVariantObject();
            return (this.frameCompareList.length >= 2) && (!this.isSelected(frameObj));
        } else {
            return (this.frameCompareList.length >= 2) && (!this.isSelected(frame));
        }
    }

    getDefaultFrameVariantObject() {
        let frameObj = {} as Frame;
        frameObj.id = this._frameIdForImage$.getValue();
        frameObj.coating = null;
        frameObj.tint = null;
        return frameObj;
    }

    public toggleCompareframe(frame: Frame) {
        const frameIdForImage = this._frameIdForImage$.getValue();

        if (frameIdForImage !== frame.id) {
            const i = this.frameCompareList.findIndex(x => x.frameId === frameIdForImage && (x.coatingId == null || undefined) && (x.tintId == null || undefined));

            if (i !== -1) {
                this.frameCompareList.splice(i, 1);
                this.debug.log('removed frame variant item');
            } else if (this.frameCompareList.length < 2) {
                const compareFrame = { frameId: frameIdForImage, coatingId: null, tintId: null, lastUpdate: new Date(Date.now()) } as FavoriteFrame;
                this.frameCompareList.push(compareFrame);
                this.debug.log('pushed frame variant item');
            }

        } else {
            const i = this.frameCompareList.findIndex(x => x.frameId === frame.id && x.coatingId == frame.coating?.catalogCode && x.tintId == frame.tint?.catalogCode);
            this.onChange.emit('');
            if (i !== -1) {
                this.frameCompareList.splice(i, 1);
                this.debug.log('removed item');
            } else if (this.frameCompareList.length < 2) {
                const compareFrame = { frameId: frame.id, coatingId: frame.coating?.catalogCode, tintId: frame.tint?.catalogCode, lastUpdate: new Date(Date.now()) } as FavoriteFrame;
                this.frameCompareList.push(compareFrame);
                this.debug.log('pushed item');
            }
        }
        this.onCompareCheckboxChange.emit({endIndex: this.endIndex,scrollToPosition: this.scrollToPosition,dmtFlag: this.dmtFlag});
        this.cd.detectChanges();
    }

    public getColorDotGrid(variantsLength: number) {
        switch (variantsLength) {
            case 0:
                return '';
            case 1:
                return 's4 offset-s4';
            case 2:
                return 's6';
            case 3:
                return 's4';
            case 4:
                return 's3';
            default:
                return 's3';
        }
    }

    public async onColorDotClicked(variantFrameId: string) {
        if (this.initialFrameId === null || this.initialFrameId === undefined) {
            this.initialFrameId = this.frameIdForImage;
        }
        if (this.frameIdForImage != variantFrameId) {
            this.frameImage$.next(await this.getFrameImage(variantFrameId));
            this.frameIdForImage = variantFrameId;
            this._frameIdForImage$.next(variantFrameId);
            this.debug.log(`%c[FrameHost]%c received event for %cCOLOR_DOT_CLICKED %cwith frameId %c${variantFrameId}`, cs.prefix, cs.clear, cs.info, cs.clear, cs.success);
            this.onChange.emit();
        }
        this.cd.detectChanges();
    }

    public tryOn(frameId: string) {        
        let params = { frameId, from: 'gallery', mode: this.mode, coating: null, tint: null,scrollPosition:null,endIndex:null,dmt:null,list:null};
        if (this.scrollToPosition || this.scrollToPosition == "0" ){   
            params.scrollPosition = parseInt(this.scrollToPosition);
            params.endIndex = this.endIndex;
            params.list = this.isList;
            if(this.dmtFlag){
                params.dmt = true;
            }
        }
        if (this.frame.id === this._frameIdForImage$.getValue()) {
            params.coating = this.coating?.catalogCode;
            params.tint = this.tint?.catalogCode;
        }

        this.router.navigate([`/viewer/${this.sessionId}`], { queryParams: params });
    }

    public order(frameId: string) {
        let params = { frameId, from: 'gallery', mode: this.mode, coating: null, tint: null,scrollPosition:null,endIndex:null,dmt:null};
        if (this.scrollToPosition || this.scrollToPosition == "0" ){   
            params.scrollPosition = parseInt(this.scrollToPosition);
            params.endIndex = this.endIndex;
            if(this.dmtFlag){
                params.dmt = true;
            }
        }

        if (this.frame.id === this._frameIdForImage$.getValue()) {
            params.coating = this.coating?.catalogCode;
            params.tint = this.tint?.catalogCode;
        }

        this.router.navigate([`/order/${this.sessionId}/${frameId}`], { queryParams: params });
    }

    public removeRecommendation(frameId: string) {
        event.preventDefault();
        event.cancelBubble = true;

        const subscriber = this._session.selectedSession$.pipe(
            first(),
            mergeMap(session => {
                return this._frames.removeRecommendation(frameId,session.id);
            }),
            finalize(() => subscriber.unsubscribe())
        ).subscribe();
    }

    public flipFrameTile(): void {
        this.flipped = !this.flipped;
    }
}
