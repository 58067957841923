import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ChangeContext, Options } from '@angular-slider/ngx-slider';
import { combineLatest, Observable } from 'rxjs';
import { filter, shareReplay, tap } from 'rxjs/operators';
import { DebugService } from '../../../services/debug.service';
import { FeatureFlag } from '../../models/ecp-settings.model';
import { FilterItems } from '../../models/filterItems';
import { FrameFilterService } from '../../services/frame-filter.service';
import { FormatPricePipe } from '../../pipes/format-price.pipe';
import { FrameService } from '../../services/frame.service';
import { ViewerService } from '../../services/viewer.service';

@Component({
    selector: 'vis-filter',
    templateUrl: './filter.component.html',
    styleUrls: ['./filter.component.scss']
})

export class FilterComponent {

    @Input() 
    private sessionId;

    @Output()
    public isFilterbarClosed: EventEmitter<boolean> = new EventEmitter();

    private frameCurrencyCode: string = 'EUR';

    constructor(
        public frameFilter: FrameFilterService,
        public _frames: FrameService,
        public _viewer: ViewerService,
        private debug: DebugService,
        private formatPrice:FormatPricePipe
    ){}


    public sliderOptions: Options = {
        floor: 0,
        ceil: this.frameFilter.highestPriceAvailable,
        disabled: false,
        hideLimitLabels: false,
        hidePointerLabels: false,
        translate: (value: number): string => {
            return `${this.formatPrice.transform(value)}${this.frameCurrencyCode}`;
        },
    };

    public priceFilterSubscription;

    public readonly filterTypeList: FilterItems[] = [
        { filterText: 'pages.gallery.filters.spectacle_sun', filterIcon: 'sun-frame', filterType: 'sun' },
        { filterText: 'pages.gallery.filters.spectacle_optical', filterIcon: 'optical-frame', filterType: 'optical' }
    ];

    public readonly filterShapeList: FilterItems[] = [
        { filterText: 'pages.gallery.filters.square', filterIcon: 'square-frame', filterType: 'SQUARE' },
        { filterText: 'pages.gallery.filters.rectangular', filterIcon: 'rect-frame', filterType: 'RECTANGLE' },
        { filterText: 'pages.gallery.filters.round', filterIcon: 'round-frame', filterType: 'ROUND' },
        { filterText: 'pages.gallery.filters.pilot', filterIcon: 'pilot-frame', filterType: 'PILOT' },
        { filterText: 'pages.gallery.filters.butterfly', filterIcon: 'butterfly-frame', filterType: 'BUTTERFLY_CATEYE' },
        { filterText: 'pages.gallery.filters.panto', filterIcon: 'panto-frame', filterType: 'PANTO'}
    ];

    public readonly filterRimList: FilterItems[] = [
        { filterText: 'pages.gallery.filters.fullrim', filterIcon: 'fullrim-frame', filterType: 'FULLRIM' },
        { filterText: 'pages.gallery.filters.nylor', filterIcon: 'nylor-frame', filterType: 'NYLOR' },
        { filterText: 'pages.gallery.filters.rimless', filterIcon: 'rimless-frame', filterType: 'RIMLESS' }
    ];

    public readonly filterColorList: FilterItems[] = [
        { filterText: 'pages.gallery.filters.colorful', filterIcon: 'color-frame', filterType: 'isColorful' },
        { filterText: 'pages.gallery.filters.dark', filterIcon: 'dark-frame', filterType: 'isDark' },
        { filterText: 'pages.gallery.filters.light', filterIcon: 'light-frame', filterType: 'isLight' }
    ];

    public readonly filterLifestyleList: FilterItems[] = [
        { filterText: 'pages.gallery.filters.elegant', filterIcon: 'elegant-frame', filterType: 'ELEGANT' },
        { filterText: 'pages.gallery.filters.sporty', filterIcon: 'sporty-frame', filterType: 'SPORTY' },
        { filterText: 'pages.gallery.filters.classic', filterIcon: 'classic-frame', filterType: 'CLASSIC' },
        { filterText: 'pages.gallery.filters.urban', filterIcon: 'urban-frame', filterType: 'URBAN' },
        { filterText: 'pages.gallery.filters.stylish', filterIcon: 'stylish-frame', filterType: 'STYLISH' }
    ];

    public readonly filterMaterialList: FilterItems[] = [
        { filterText: 'pages.gallery.filters.wood', filterIcon: 'wood-frame', filterType: 'WOOD' },
        { filterText: 'pages.gallery.filters.plastic', filterIcon: 'plastic-frame', filterType: 'PLASTIC' },
        { filterText: 'pages.gallery.filters.metal', filterIcon: 'metal-frame', filterType: 'METAL' },
        { filterText: 'pages.gallery.filters.titanium', filterIcon: 'titanium-frame', filterType: 'TITANIUM' },
        { filterText: 'pages.gallery.filters.combination', filterIcon: 'combination-frame', filterType: 'COMBINATION' },
        { filterText: 'pages.gallery.filters.buffalo', filterIcon: 'buffalo-frame', filterType: 'BUFFALO_HORN' },
        { filterText: 'pages.gallery.filters.acetate', filterIcon: 'acetate-frame', filterType: 'ACETATE' },
        { filterText: 'pages.gallery.filters.carbon', filterIcon: 'carbon-frame', filterType: 'CARBON' },
        { filterText: 'pages.gallery.filters.aluminium', filterIcon: 'aluminium-frame', filterType: 'ALUMINIUM' },
        { filterText: 'pages.gallery.filters.steel', filterIcon: 'steel-frame', filterType: 'STAINLESS_STEEL' },
        { filterText: 'pages.gallery.filters.others', filterIcon: 'others-frame', filterType: 'OTHERS' },

    ];

    public readonly filterTargetList: FilterItems[] = [
        { filterText: 'pages.gallery.filters.female', filterIcon: 'female-frame', filterType: 'FEMALE' },
        { filterText: 'pages.gallery.filters.male', filterIcon: 'male-frame', filterType: 'MALE' },
        { filterText: 'pages.gallery.filters.unisex', filterIcon: 'unisex-frame', filterType: 'UNISEX' },
        { filterText: 'pages.gallery.filters.kids', filterIcon: 'kids-frame', filterType: 'CHILDREN' }
    ];

    ngOnInit(): void {
        this.priceFilterSubscription = this._frames.frsSortedFrameCatalogue$
            .pipe(
                filter((f) => f.length > 0),
                tap((frames) => {
                    const max = Math.max(...frames.map((f) => f.retailPrice));
                    this.debug.log(max);
                    this.changeHighestPriceValue(max);

                    // get currency of frames.
                    const frameCurrencies = frames.map((f) => f.currency)?.filter(code => code != null && code != undefined);
                    if (frameCurrencies.length > 0) {
                        const distinctCurrency = [... new Set(frameCurrencies)];
                        if (distinctCurrency.length > 0) this.frameCurrencyCode = distinctCurrency[0];
                    }
                })
            )
            .subscribe();
    }

    ngOnDestroy() {
        if (this.priceFilterSubscription) {
            this.priceFilterSubscription.unsubscribe();
        }
    }

    public close(): void {
        /* TODO: Find better name for event emitter */
        this.isFilterbarClosed.emit(false);
    }

    public priceInputChange(context: ChangeContext): void {
        const f = Object.assign(
            {},
            {
                ...this.frameFilter.filtersForPreview$.getValue(),
                ...(context.value != 0 ||
                    context.highValue != this.frameFilter.highestPriceAvailable
                    ? { price: [context.value, context.highValue] }
                    : { price: null }),
            }
        );
        this.frameFilter.selectedFilters = f;
        this.frameFilter.filtersForPreview$.next(f);
    }

    private changeHighestPriceValue(price: number): void {
        this.frameFilter.highestPriceAvailable = price;
        const optionsCopy = Object.assign({}, this.sliderOptions);
        optionsCopy.ceil = this.frameFilter.highestPriceAvailable;
        this.sliderOptions = optionsCopy;
    }
}