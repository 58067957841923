<div class="row">
    <div class="overlay" *ngIf="isLoading">
        <mat-spinner mode="indeterminate"></mat-spinner>
    </div>

    <div class="contact-card-wrapper col s12">
        <h1 class="col s11">{{ 'components.contact_ecp_card.title' | translate }}</h1>
        <button (click)="close()" class="col s1 close">
            <mat-icon>close</mat-icon>
        </button>
        <form #contactForm autocomplete="off">

            <mat-form-field appearance="fill">
                <mat-label class="contact-label">{{'components.inputs.message.label' | translate }}</mat-label>
                <textarea vcldAutomationId="consr_lib-contact-ecp-card_msg-area_input" matInput [formControl]="messageField"
                    placeholder="{{ 'components.inputs.message.placeholder' | translate }}" autocomplete="off"
                    required></textarea>
                <mat-error>{{ 'components.validation.errors.required' | translate }}</mat-error>
            </mat-form-field>

            <mat-form-field featureFlag="EmailEnabled" appearance="fill">
                <mat-label class="contact-label">{{ 'components.inputs.useremail.label' | translate }}</mat-label>
                <input matInput [formControl]="userMailField" readonly
                    placeholder="{{ 'components.inputs.useremail.placeholder' | translate }}" autocomplete="off"
                    required />
                <mat-error>{{ 'components.validation.errors.email' | translate }}</mat-error>
            </mat-form-field>

            <mat-form-field featureFlag="SMSEnabled" appearance="fill">
                <mat-label class="contact-label">{{ 'components.inputs.userphone.label' | translate }}</mat-label>
                <input matInput [formControl]="userMailField" readonly
                    placeholder="{{ 'components.inputs.userphone.placeholder' | translate }}" autocomplete="off"
                    required />
                <mat-error>{{ 'components.validation.errors.phone' | translate }}</mat-error>
            </mat-form-field>

            <button vcldAutomationId="consr_lib-contact-ecp-card_submit-msg_btn" mat-flat-button color="primary" (click)="onSubmit()">{{ 'components.buttons.submit' |
                translate }}</button>
        </form>
    </div>
</div>