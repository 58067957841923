import { Inject, Component, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { GoogleTagManagerService, GTMLayer } from '../../vto/services/gtm.service';
import { Language } from '../../configs/constant.flags';
import { LanguageService } from '../../services/language.service';
import { map, shareReplay, tap } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'vis-adm-language-dialog',
    templateUrl: 'language-selection.dialog.component.html',
    styleUrls: ['language-selection.dialog.component.scss']
})
export class LanguageSelectionDialogComponent implements OnInit {

    public get availableLanguages() {
        return this.languages.getLanguages;
    }

    public languages$ = this.languages.availableLanguages$.pipe();

    public listRowCount$: BehaviorSubject<number> = new BehaviorSubject(2);
    public languageMap$: Observable<any[][]>;


    constructor(
        private dialogRef: MatDialogRef<LanguageSelectionDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private data,
        public translate: TranslateService,
        private gtm: GoogleTagManagerService,
        private languages: LanguageService
    ) { }

    public ngOnInit() {
        this.languageMap$ = combineLatest([this.languages$, this.listRowCount$]).pipe(
            map(([languages, rows]) => {
                const langWorker = Object.assign([], languages);
                const langRows: any[][] = [[], [], [], []];
                const length = langWorker.length;
                let mod = length % rows;
                const rowAmount = Math.floor(length / rows);

                for (let i = 0; i < rows; i++) {

                    let currentAmount = rowAmount;
                    if (mod > 0) {
                        currentAmount = currentAmount + 1;
                        mod--;
                    }

                    let colLang = langWorker.splice(0, currentAmount);
                    langRows[i].push(...colLang);

                }

                return langRows;
            }),
        );
        this.recalculateRows();
    }

    private setBrowserLanguage(language: string): void {
        language = localStorage.getItem('vcldlang') || 'en';

        (window as any).language = language;
        document.getElementsByTagName('html')[0].setAttribute('lang', language);
    }

    useLanguage(language: string) {
        this.translate.use(language);
        localStorage.setItem('vcldlang', language);

        this.setBrowserLanguage(language);

        this.gtm.pageView('/language-selection');
        this.gtm.add = { [GTMLayer.pageLanguage]: language };
        this.gtm.push();

    }

    public recalculateRows() {

        if (window.innerHeight < 450) {
            this.listRowCount$.next(4);
        } else {
            this.listRowCount$.next(2);
        }
    }
}
