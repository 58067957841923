import { ChangeContext } from '@angular-slider/ngx-slider';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-vcld-slider',
  templateUrl: './vcld-slider.component.html',
  styleUrls: ['./vcld-slider.component.scss']
})
export class VcldSliderComponent {

  @Input()
  public options: string;

  @Input()
  public value: string;
  
  @Input()
  public highValue: string;

  @Input()
  public direction: string;

  @Input()
  public customClasess: string;


  @Output()
  public userValueChange = new EventEmitter();


  @Output()
  public valueChange = new EventEmitter();
  
  @Output()
  public sliderValueChanged  = new EventEmitter();

  onInputChange(event) {
    const direction = this.direction;
    this.userValueChange.emit({event, direction});
  }

  onValueChange(event: number | ChangeContext) {
    const direction = this.direction;
    this.valueChange.emit({event, direction});
  }
}
