import { AfterContentInit, AfterViewChecked, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Host, Input, OnChanges, OnInit, ViewChild } from '@angular/core';

@Component({
    selector: 'vis-filter-tile',
    templateUrl: './filter-tile.component.html',
    styleUrls: ['./filter-tile.component.scss']
})

export class FilterTileComponent {

    constructor() {
                // Inject dependencies /set the default values for properties/ initialization code here. 
    
    }

    @Input()
    public filterTileText: string;

    @Input()
    public filterIcon: string;

    @Input()
    public selected?: boolean;

    public isDisabled: boolean = true;
}