<mat-dialog-content class="row">
    <h3 *ngIf="(favoritesCount$ | async) > 0" class="bold" vcldAutomationId="consr_vto_vis-frame-selection-dialog_your-favorites_hdr">{{"components.dialogs.frame-selection.favorites" | translate }} ({{(favoritesCount$ | async)}})</h3>
    <div *ngIf="(favoritesCount$ | async) > 0" class="favorites-list">
        <div class="list-item col s12" *ngFor="let frame of favoritedFrames$ | async; let last = last">
            <mat-icon vcldAutomationId="consr_vis-frame-selection-dialog_add-frame_icon" class="col s2"
                [ngClass]="[(selectedFrames$ | async | isfavorite: frame) ? 'yellow-text' : 'cyan-text', 
                            ((selectedFrames$ | async).length === 5) ? 'disable' : '', ((selectedFrames$ | async).length === 1) ? 'disabled' : '' ]"
                (click)="toggleFrame(frame)" matTooltip="{{'tooltips.order.max_amount' | translate : {maxSelectionAmount : maxSelectionAmount} }}"
                [matTooltipDisabled]="(selectedFrames$ | async).length < 5">
                {{(selectedFrames$ | async | isfavorite: frame) ? 'check_circle' : 'add_circle'}}
            </mat-icon>
            <vcld-secure-image class="col s4" width="50%" height="" [imgSrc]="getFrameImage(frame.id)">
               
            </vcld-secure-image>
            <div class="col s1">
                <i *ngIf="hasFavLenses(frame)" class="icon lenses-fav"></i>
            </div>
            <div class="col s5">
                <p>{{frame.brand}}</p>
                <p *ngIf="(_viewer.ecpSettings$ | async)?.modelNameEnabled">{{frame.model}}</p>
            </div>
            <hr *ngIf="!last">
        </div>
    </div>


    <h3 *ngIf="(dmtCount$ | async) > 0" class="bold" vcldAutomationId="consr_vto_vis-frame-selection-dialog_your-recommendations_hdr" style="margin-top: 24px;">{{"components.dialogs.frame-selection.recommendations" | translate }} ({{(dmtCount$ |
        async)}}) </h3>
    <div *ngIf="(dmtCount$ | async) > 0" class="dmt-list" [ngClass]="(favoritesCount$ | async) > 0 ? 'dmt-fav-list':''">
        <div class="list-item col s12" *ngFor="let frame of dmtFrames$ | async; let last = last">

            <mat-icon class="col s2" vcldAutomationId="consr_vto_vis-frame-selection-dialog_add-frame_icon"
                [ngClass]="[(selectedFrames$ | async | isfavorite: frame) ? 'yellow-text' : 'cyan-text' , ((selectedFrames$ | async).length === 5) ? 'disable' : '', ((selectedFrames$ | async).length === 1) ? 'disabled' : '' ]"
                (click)="toggleFrame(frame)"
                matTooltip="{{'tooltips.order.max_amount' | translate : {maxSelectionAmount : maxSelectionAmount} }}"
                [matTooltipDisabled]="(selectedFrames$ | async).length < 5">{{(selectedFrames$ | async | isfavorite: frame) ? 'check_circle' :
                'add_circle'}} </mat-icon>
            <vcld-secure-image class="col s5" width="40%" height="" [imgSrc]="getFrameImage(frame.id)">
            </vcld-secure-image>
            <div class="col s5">
                <p>{{frame.brand}}</p>
                <p>{{frame.model}}</p>
            </div>
            <hr *ngIf="!last">
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button vcldAutomationId="consr_vis-frame-selection-dialog_cancel-frame_btn" mat-button color="primary" [mat-dialog-close]="null">{{"components.buttons.cancel" | translate }}</button>
    <button vcldAutomationId="consr_vis-frame-selection-dialog_add-frame_btn" mat-flat-button color="primary" (click)="submitFrameSelection()">{{"components.buttons.add" | translate }}</button>
</mat-dialog-actions>