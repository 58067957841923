<div [class.small-frame-tile]="!isList && isSmallScreen" [class.frame-tile]="isList || !isSmallScreen" *ngIf="!flipped" vcldAutomationId="consr_vto_vis-gallery-frame-host_frames-list">
    <!-- Like -->
    <div [ngClass]="{'change-trigger': (changeTrigger$ | async) == ''}">
        <div class="btns-wrapper" vcldAutomationId="consr_vto_vis-gallery-frame-host_favorite-wrapper_btn">
            <button mat-icon-button vcldAutomationId="consr_vto_vis-gallery-frame-host_favorite_btn" (click)="favoriteSelectedFrame(frame)" #tooltipFavorites="matTooltip"
                matTooltipClass="favorite-tooltip" matTooltip="{{((isFavoritedFrame$ | async) ? 'tooltips.viewer.fav_filled' : 'tooltips.viewer.fav_unfilled') | translate}}"
                matTooltipPosition="above">
                <mat-icon> {{(isFavoritedFrame$ | async) ? "favorite" :
                    "favorite_border" }}</mat-icon>
            </button>
            <button mat-icon-button vcldAutomationId="consr_vto_vis-gallery-frame-host_close_btn" (click)="removeRecommendation(frame.id)" class="remove-btn quick-silver-text"
                *ngIf="dmtFlag">
                <mat-icon matTooltip="{{ 'tooltips.viewer.remove_recommendation' | translate }}"> close </mat-icon>
            </button>
            <button mat-icon-button class="remove-btn quick-silver-text" *ngIf="(coating || tint) && !dmtFlag && frame.id == frameIdForImage" (click)="flipFrameTile()" matTooltip="{{'tooltips.gallery.lenses' | translate}}" matTooltipPosition="above">
                <i class="icon lenses-fav"></i>
            </button>
        </div>
    </div>

    <vcld-secure-image width="80%" height="" class="gallery-image" *ngIf="(isFrameAvailable$ | async)"
        [ngClass]="!(isFrameAvailable$ | async) ? 'disabled' : null" [imgSrc]="frameImage$"
        (click)="tryOn(frameIdForImage)">
    </vcld-secure-image>
    <vcld-secure-image width="80%" height="" class="gallery-image" *ngIf="!(isFrameAvailable$ | async)"
        [ngClass]="!(isFrameAvailable$ | async) ? 'disabled' : null" [imgSrc]="frameImage$">
    </vcld-secure-image>

    <div class="colorpicker" *ngIf="isList && frame.variants?.length > 1">
        <div class="dots-wrapper" (click)="$event.stopPropagation()">
            <vis-frame-color-dot *ngFor="let frameVariant of frame.variants?.slice(0, 3) " [frameId]="frameVariant.id"
                [selected]="frameIdForImage == frameVariant.id" [ngClass]="getColorDotGrid(frame.variants?.length)"
                (onChange)="onColorDotClicked($event)" [colorName]="frameVariant.color" [layoutcss]="'gallery-frame'">
            </vis-frame-color-dot>
            <div *ngIf="frame.variants?.length > 3">
                <mat-icon class="plus" #tooltipMore="matTooltip"
                    matTooltipClass="more-tooltip" matTooltip="{{'tooltips.gallery.more_variants' | translate}}"> add_circle_outline</mat-icon>
            </div>

        </div>

    </div>
</div>
<div class="frame-tile" *ngIf="flipped">
    <div class="backside-wrapper">
        <button mat-icon-button (click)="flipFrameTile()" class="remove-btn quick-silver-text">
            <mat-icon>
                arrow_back_ios_new
            </mat-icon>
        </button>
        <p class="bold" vcldTooltip>{{'pages.gallery.lenses' | translate }}</p>     
        <div *ngIf="coating || tint" class="fake-alignment"></div>
    </div>
    <div class="tintcoating">
        <div *ngIf="coating">
            <img *ngIf="coatingThumbnail; else fallbackCoatingIcon" [src]="coatingThumbnail" class="visucloud-icons">

            <ng-template #fallbackCoatingIcon>
                <i [class]="_tintsCoatings.getCoatingIcon(coating)"></i>
            </ng-template>

            <p>
                {{ coating | tintCoatingName: translationObjName }}
            </p>
        </div>
        <div *ngIf="tint">
            <!-- tint-icon-style -->
            <img *ngIf="tintThumbnail; else fallbackTintIcon" [src]="tintThumbnail" class="visucloud-icons">

            <ng-template #fallbackTintIcon>
                <i [class]="_tintsCoatings.getTintByGroupId(tint)"></i>
            </ng-template>

            <p> 
                {{_tintsCoatings.getTintUIName(tint)}} {{_tintsCoatings.getTintUIValue(tint)}}
            </p>
        </div>
    </div>
</div>


<!-- Text -->
<div class="frame-details" vcldAutomationId="consr_vto_vis-gallery-frame-host_all-frame-details_tiles" [ngClass]="!(isFrameAvailable$ | async) ? 'disabled' : null">
    <p class="bold" vcldAutomationId="consr_vto_vis-gallery-frame-host_all-frame-details_brand" vcldTooltip> {{ frame.brand }} </p>
    <p *ngIf="modelNameFlag" class="bold" vcldAutomationId="consr_vto_vis-gallery-frame-host_all-frame-details_model" vcldTooltip> {{ frame.model }} </p>
    <p *ngIf="pricingFlag" vcldAutomationId="consr_vto_vis-gallery-frame-host_all-frame-details_price">
        <ng-container *ngIf="frame.retailPrice">{{ frame.currency }}</ng-container> {{ (frame.retailPrice | formatPrice: true) }}<span *ngIf="frame?.retailPrice">*</span>
    </p>
</div>

<!-- Buttons & Availability -->
<div [class.show-buttons]="isList" [class.hide-buttons]="!isList" [ngClass]="{'availability-container' : !(isFrameAvailable$ | async)}">
    <div [style.display]="(isFrameAvailable$ | async) ? '' : 'none'">
        <div style="display: flex; flex-direction: column; gap: 12px;">
            <div class="interaction-wrapper">
                <button mat-stroked-button color="primary" vcldAutomationId="consr_vto_vis-gallery-frame-host_tryon_btn" (click)="tryOn(frameIdForImage)" class="btn-round"> {{ "components.buttons.try_on" | translate }}   </button>
                <button mat-flat-button color="primary" vcldAutomationId="consr_vto_vis-gallery-frame-host_select_btn" (click)="order(frameIdForImage)" class="btn-round"> {{ "components.buttons.order" | translate }} </button>
            </div>
            <div style="text-align: center;" *ngIf="showCompare" vcldAutomationId="consr_vto_vis-gallery-frame-host_compare_cbox">
                <mat-checkbox color="primary" (change)="toggleCompareframe(frame)" [checked]="isSelected(frame)" class="compare-pointer"
                    #tooltipCompare="matTooltip" matTooltipClass="compare-tooltip"
                    matTooltip="{{isCompareDisabled(frame) && (!isSelected(frame)) ? ('tooltips.gallery.max_compare' | translate) : ''}}"
                    [disabled]="isCompareDisabled(frame)">
                    {{ "components.checkbox.compare" | translate }}
                </mat-checkbox>
            </div>
        </div>
    </div>
    <div class="availability-status" *ngIf="!(isFrameAvailable$ | async)">
        <p class="quick-silver-text" *ngIf="(isFramePermanentlyUnavailable$ | async)"> {{ "pages.gallery.permanently" | translate }} </p>
        <p class="quick-silver-text" *ngIf="(isFrameTemporaryUnavailable$ | async)"> {{ "pages.gallery.currently" | translate }} </p>
        <p class="quick-silver-text" *ngIf="(availableAgainFrom$ | async)"> {{ "pages.gallery.available" | translate }}: {{frame.availableAgainFrom}} </p>
    </div>
</div>