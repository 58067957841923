<ng-content *ngIf="focused; then searchField else searchFieldIcon"></ng-content>

<ng-template #searchField>
    <mat-form-field style="border: 1px solid black; border-radius: 50px; width: 100%;">
        <input autofocus (blur)="blurSearchbar()" vcldAutomationId="consr_vto_vis-frame-gallery-search_search-input_btn" (input)="onSearch($event.target.value)" matInput type="text"
            [placeholder]="'pages.gallery.search' | translate" #searchInput/>
        <button mat-icon-button (mousedown)="clearSearch($event)" >
            <mat-icon vcldAutomationId="consr_vto_vis-frame-gallery-search_close_btn" class="black-text">close</mat-icon>
        </button>
    </mat-form-field>
</ng-template>

<ng-template #searchFieldIcon>
    <div style="position: relative;">
        <button mat-icon-button (click)="openSearch()" [ngClass]="{
            'filter-active': !!searchValue
        }" class="search-btn">
            <mat-icon>search</mat-icon>
            <span *ngIf="!!searchValue">(1)</span>
        </button>
    </div>
</ng-template>