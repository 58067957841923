<div class="container">
    <div class="image-container">
        <div class="branding-container">
            <div *ngIf="(_session.preSelectedSession$ | async)">
                <vcld-cobranding [session]="_session.preSelectedSession$ | async"
                    customStyle="display: flex; justify-content: flex-end;">
                </vcld-cobranding>
            </div>
        </div>
        <div class="image hide-on-landscape"></div>
        <p class="greeting-headline" vcldAutomationId="consr_vto_vis-profile-page_consumer-name_lbl"> {{ "pages.profile.greeting_message_title"| translate: { name: userName } }} </p>
        <p class=""> {{ "pages.profile.greeting_message_body" | translate }} </p>
        <p class=""> {{ "pages.profile.description" | translate }} </p>
    </div>

    <div class="avatar-container">
        <p class="avatar-headline hide-on-landscape"> {{ "pages.profile.your_avatars" | translate }} </p>
        <div class="" *ngIf="!(_session.sessionsList$ | async); else content">
            <mat-spinner></mat-spinner>
        </div>

        <ng-template #content>
            <mat-list *ngIf="(_session.sessionsList$ | async)?.length > 0; else noSessions;">
                <div vcldAutomationId="consr_vto_vis-profile-page_avatars_list" class="session-item" *ngFor="let i = index; let session; of: (_session.sessionsList$ | async); let last = last; let first = first;">
                    <hr class="top-line" *ngIf="first">
                    <mat-list-item class="">
                        <div matListItemAvatar vcldAutomationId="consr_vto_vis-profile-page_avatar_timg">
                            <img *ngIf="getAvatarImage(session.id) == null;" matListItemAvatar src="../../../../assets/images/avatar-placeholder.png" alt="your avatar" />
                            <img *ngIf="getAvatarImage(session.id) != null;" matListItemAvatar [src]="getAvatarImage(session.id)" alt="your avatar" />
                            <button matTooltip="{{'components.dialogs.delete.tooltip' | translate }}" class="delete-icon" mat-icon-button *ngIf="session.opticianName" (click)="onDelete(session.id)">
                                <mat-icon vcldAutomationId="consr_vto_vis-profile-page_delete-your-avatar_btn">delete</mat-icon>
                            </button>
                        </div>
                        <p vcldAutomationId="consr_vto_vis-profile-page_store-name_text" matListItemTitle vcldTooltip  class="optician-headline">{{ session.opticianName || session.opticianId }}</p>
                        <p vcldAutomationId="consr_vto_vis-profile-page_created-on_text" matListItemLine class="quick-silver-text"> {{ "pages.profile.last_visit"| translate: {timestamp: session.createdAt | date: "dd.MM.yyyy" } }} </p>
                        <p vcldAutomationId="consr_vto_vis-profile-page_last-online-access-not-null_text" matListItemLine class="quick-silver-text" *ngIf="session.lastAccess != null"> {{ "pages.profile.last_access" | translate : { timestamp: session.lastAccess | date: "dd.MM.yyyy" || "-" } }} </p>
                        <p vcldAutomationId="consr_vto_vis-profile-page_last-online-access-broken_session_message" matListItemLine *ngIf="_datacache.getSessionAssetStatus(session.id) === false" class="quick-silver-text broken-session-warning-text">{{ "pages.profile.broken_session_note" | translate }}</p>
                    </mat-list-item>
                    <mat-list-item class="link-list allign-mdc-list-item" *ngIf="(session.opticianName && (_datacache.getSessionAssetStatus(session.id) !== false))">
                        <span matListItemLine>
                           <i class="icon visucloud-icons avatar cyan"></i>
                            <a vcldAutomationId="consr_vto_vis-profile-page_select-your-avatar_lnk" (click)="jumpToViewer(session)">{{"components.links.select_avatar"| translate}}</a>
                        </span>
                        <span matListItemLine>
                            <i class="icon visucloud-icons heart cyan-text"></i>
                            <a vcldAutomationId="consr_vto_vis-profile-page_see-your-favorites_lnk" (click)="jumpToFavorites(session)">{{"components.links.favourites" | translate }}</a>
                        </span>
                        <span matListItemLine>
                            <i class="icon visucloud-icons frame cyan-text"></i>
                            <a vcldAutomationId="consr_vto_vis-profile-page_browse-frames-optician_lnk" (click)="jumpToGallery(session)">{{"components.links.browse_frames" | translate }}</a>
                        </span>
                        <span matListItemLine>
                            <i class="icon visucloud-icons email cyan-text"></i>
                            <a vcldAutomationId="consr_vto_vis-profile-page_contact-my-optician_lnk" (click)="onContactSupport(session)">{{ "pages.order.contact_optician" | translate }}</a>
                        </span>
                    </mat-list-item>
                    <hr *ngIf="!last">
                </div>
            </mat-list>

            <ng-template #noSessions>
                <p class="">{{ 'pages.profile.no_sessions' | translate }}</p>
            </ng-template>
        </ng-template>
    </div>
</div>