import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'vis-confirm-dialog',
    templateUrl: './confirm.dialog.component.html',
    styleUrls: ['./confirm.dialog.component.scss']
})
export class ECPConfirmOrderDialogComponent {

    @ViewChild('commentInput', { static: true })
    public commentInputElement;

    constructor(
        public dialogRef: MatDialogRef<ECPConfirmOrderDialogComponent>,
        private translate: TranslateService,
        ) { }

    onCancelClick(): void {
        this.dialogRef.close({ decision: false, comment: this.commentInputElement.nativeElement.value });
    }

    onYesClick(): void {
        if(this.commentInputElement.nativeElement.value.length === 0){
            this.dialogRef.close({ decision: true, comment: null });
        } else {
            this.dialogRef.close({ decision: true, comment: this.commentInputElement.nativeElement.value });
        }
    }
}
