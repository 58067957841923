export const PRIVACY_FLAG = 'vis-privacy';
export const SESSIONID_FLAG = 'vcld_sid';
export const FRAMEID_FLAG = 'vcld_fid';
export const DEBUG_FLAG = 'vcld_debug';
export const MOCK_FLAG = 'vcld_debug_mock';

export type Language = {
    name: string;
    code: string;
};

export type ConsoleStyle = {
    prefix: string,
    headline: string,
    success: string,
    error: string,
    warning: string,
    info: string,
    clear: string
}

// export const availableLanguages: Language[] = [
//     { name: 'Čeština', code: 'cs-CZ' },
//     { name: 'Dansk', code: 'da-DK' },
//     { name: 'Deutsch', code: 'de-DE' },
//     { name: 'Dutch - Belgium', code: 'nl-BE' },
//     { name: 'Dutch - Nederlands', code: 'nl-NL' },
//     { name: 'English', code: 'en-US' },
//     { name: 'Español', code: 'es-ES' },
//     { name: 'Français', code: 'fr-FR'},
//     { name: 'Italiano', code: 'it-IT' },
//     { name: 'Norsk', code: 'nn-NO'},
//     { name: 'Português', code: 'pt-PT' },
//     { name: 'Slovenčina', code: 'sk-SK'},
//     { name: 'Suomi', code: 'fi-FI'},
//     { name: 'Svenska', code: 'sv-SE' },
//     { name: '日本語', code: 'ja-JP' },
//     { name: '中文', code: 'zh-CN' },
// ];

export const CONSOLE_STYLE: ConsoleStyle = {
    prefix: 'font-weight: bold; color: #C39BD3;',
    headline: 'font-weight: bold; color: #34495E;',
    success: 'color: #58D68D; font-style: italic;',
    error: 'color: #EC7063; font-style: italic;',
    warning: 'color: #F5B041; font-style: italic;',
    info: 'color: #85C1E9; font-style: italic;',
    clear: ''
}

export enum GLASS_TYPE_ELIGIBILITY {
    RX = 'RX',
    SUN = 'SUN',
    RX_WITH_TIN = 'RX_WITH_TIN'
}