import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ViewerPageComponent } from '../pages/viewer/viewer.page.component';
import { ViewerFactoryService } from '../services/viewer.factory.service';

@Injectable({
    providedIn: 'root'
})
export class ViewerStateGuard  {

    constructor(private _factory: ViewerFactoryService) { }

    canDeactivate(component: ViewerPageComponent, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

        if (!this._factory.isPaused$.getValue() && this._factory.isInitialized$.getValue()) {
            this._factory.createNewViewerWidget().pause();
            this._factory.isPaused$.next(true);
        }

        return true;
    }
}