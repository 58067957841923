import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { PrivacyDialogComponent } from '../../dialogs/privacy/privacy.dialog.component';
import { GoogleTagManagerService, GTMCustomEvents } from '../../vto/services/gtm.service';

import { PRIVACY_FLAG, FRAMEID_FLAG, SESSIONID_FLAG } from '../../configs/constant.flags';
import { DebugService } from '../../services/debug.service';
import { CONSOLE_STYLE as cs } from '../../configs/constant.flags';
import { ConsumerAppEnvironment as environment } from 'visenvironment';
import { HttpClient } from '@angular/common/http';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'vis-recommendation-landing-page',
    templateUrl: './recommendation-landing.page.component.html',
    styleUrls: ['./recommendation-landing.page.component.scss']
})
export class RecommendationLandingPageComponent implements OnInit, AfterViewInit {

    constructor(private oAuth: OAuthService, private gtm: GoogleTagManagerService, private http: HttpClient, private dialogHost: MatDialog, private route: ActivatedRoute, private debug: DebugService) { }

    public get isAuthenticated() {
        return this.oAuth.hasValidIdToken();
    }

    async ngOnInit() {

        const sessionId = this.route.snapshot.queryParams?.sessionId;
        const frameId = this.route.snapshot.queryParams?.frameId;
        const capmaignId = this.route.snapshot.queryParams?.campaignId;

        this.debug.log(`Recommendation landing with sessionId=${sessionId} & frameId=${frameId}`);
        if (sessionId && frameId) {
            this.setSessionFlags(sessionId, frameId);

            try {
                await this.logPageView(sessionId, capmaignId);
            } catch (error) {
                this.debug.log(error);
            }

            this.debug.log(this.oAuth.options);
            this.oAuth.initLoginFlow();
        }
    }

    ngAfterViewInit() {
        const flag = this.getPrivacyFlag();
        if (!flag) {
            if (window.innerWidth > 639 && window.innerWidth <= 979 && window.innerWidth > window.innerHeight) {
                this.dialogHost.open(PrivacyDialogComponent, {
                    width: '80vw',
                    maxHeight: "90vh",
                    autoFocus: false
                });
            } else if (window.innerWidth > 601) {
                this.dialogHost.open(PrivacyDialogComponent, {
                    width: '50vw',
                    maxHeight: "90vh",
                    autoFocus: false
                });
            } else {
                this.dialogHost.open(PrivacyDialogComponent, {
                    width: '99vw',
                    height: '85vh',
                    autoFocus: false
                });
            }
            this.setPrivacyFlag();
        }
    }

    onLogin() {
        this.gtm.pushEvent(GTMCustomEvents.landing_login);
        this.oAuth.initLoginFlow();
    }

    private getPrivacyFlag(): boolean {
        let flag = false;
        if (localStorage.getItem(PRIVACY_FLAG) != null || localStorage.getItem(PRIVACY_FLAG) != undefined) {
            flag = localStorage.getItem(PRIVACY_FLAG) == 'true' ? true : false
        }

        return flag;
    }

    private setPrivacyFlag() {
        localStorage.setItem(PRIVACY_FLAG, 'true');
    }

    private setSessionFlags(sessionId: string, frameId: string): void {
        localStorage.setItem(SESSIONID_FLAG, sessionId);
        localStorage.setItem(FRAMEID_FLAG, frameId);

    }

    private async logPageView(sessionId: string, campaignId: string) {
        const payload = {
            sessionId,
            campaignId
        }
        const url = `${environment.connectivity.dmt_link_used}`;
        const result = await this.http.put(url, payload).toPromise();
        this.debug.log(`%c[DMT] %c[Tracking] %cSuccessfully logged %c${sessionId} %cfrom campaign %c${campaignId}`, cs.prefix, cs.info, cs.clear, cs.success, cs.clear, cs.success);
    }

}
