import { FavoriteFrame } from './../models/avatarcreationsession';
import { Pipe, PipeTransform } from '@angular/core';
import { DebugService } from '../../services/debug.service';
import { Frame } from '../models/frame.model';

@Pipe({ name: 'isfavorite', pure: true })
export class IsFavoritePipe implements PipeTransform {
    constructor(private debug: DebugService) { }

    transform(favorites: FavoriteFrame[], frame: Frame): boolean {
        if (!frame || !favorites) return false;

        const result = favorites?.findIndex(favt => favt.frameId === frame.id && favt.coatingId == frame.coating?.catalogCode && favt.tintId == frame.tint?.catalogCode) > -1;

        this.debug.log(
            `%c[FavoritePipe] %cChecked %c${favorites?.length} %cfor %c${frame.id} => %c${result}`,
            'color: black; font-weight: bold;',
            '',
            'color: orange; font-style: italic;',
            '',
            'color: orange; font-style: italic;',
            result ? 'color: green;' : 'color: red;'
        );
        return result;
    }
}