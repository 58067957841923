import { Component, OnInit, AfterViewInit } from "@angular/core";
import { AvatarCreationSession } from "../../models/avatarcreationsession";
import { HttpErrorResponse } from "@angular/common/http";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable, of } from "rxjs";
import { delay, map, shareReplay, tap } from "rxjs/operators";
import { OAuthService } from "angular-oauth2-oidc";
import { ZeissIdBase, ZeissIdToken } from "visauto-auth";
import { DeleteDialogComponent } from "../../dialogs/delete/delete.dialog.component";
import { ApplicationInsightsService, LogEventClassification, LogEventSender, LogEventTypes,} from "../../../services/applicationInsights.Service";
import { GoogleTagManagerService, GTMLayer } from "../../services/gtm.service";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { ContactECPCardComponent } from "../../dialogs/contact-ecp-card/contact-ecp-card.component";
import { DebugService } from "../../../services/debug.service";
import { IntroDialogComponent } from "../../dialogs/intro/intro.dialog.component";
import { InteractionType, StatisticService, VTOSessionInteraction } from '../../../services/statistic.service';
import { SnackbarComponent } from "../../../components/snackbar/snackbar.component";
import { TranslateService } from "@ngx-translate/core";
import { DataService, HttpOptions, BinaryType } from "../../services/data.service";
import { SessionService } from "../../services/session.service";
import { FeatureFlagsService } from "../../../services/featureFlag.service";

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: "vis-profile-page",
    templateUrl: "./profile.page.component.html",
    styleUrls: ["./profile.page.component.scss"],
})
export class ProfilePageComponent implements OnInit, AfterViewInit {
    public isLoading = true;
    public hasData = false;
    private canTrack = true;

    public displayedColumns = 4;

    public thumbnails: Map<string, string | SafeUrl> = new Map();
    public accessDates: Map<string, string> = new Map();

    private readonly INTRO_FLAG = "vis-intro";
    public subscription;
    public sessions$:  Observable<any>; 
    
    constructor(
        private snack: MatSnackBar,
        private dialog: MatDialog,
        private bottom: MatBottomSheet,
        private router: Router,
        private appInsight: ApplicationInsightsService,
        private debug: DebugService,
        private oAuth: OAuthService,
        private gtm: GoogleTagManagerService,
        private domSanitizer: DomSanitizer,
        private _stats: StatisticService,
        private translate: TranslateService,
        public _datacache:DataService,
        public _session: SessionService,
        private features: FeatureFlagsService
    ) { }

    public async ngOnInit() {
        await this._session.setSession(null);

        await this._session.fetchSessionsList();

        this.onResize();

        this.appInsight.logPageView("profile", window.location.href);

        this.subscription = this._session.sessionsList$.pipe(
            delay(100),
            tap((sessions) => {               
                sessions.forEach(async (session) => {
                    this._datacache.preloadAvatarWithFavorites(session);
                
                    let thumbnail:string = await this._datacache.getBinary(                        
                        BinaryType.AVATAR_THUMBNAIL,
                        session.id,
                        true
                    );
                    this.debug.log("Profile page thumbnail from new cache: " + thumbnail);
                    if (thumbnail !== null) {
                        this.thumbnails.set(
                            session.id,
                            this.domSanitizer.bypassSecurityTrustUrl(thumbnail)
                        );
                    } else {
                        this.thumbnails.set(
                            session.id,
                            "../../../../assets/images/avatar-placeholder.png"
                        );
                    }
                });
            })            
        ).subscribe();
        if (this.features.isFeatureFlagEnabled('AnnouncementBannerEnabled') && this._session.showAnnouncementBanner){
            this.snack.openFromComponent(SnackbarComponent, {
                data: {
                    icon: 'info',
                    heading: "errors.information",
                    message: "pages.profile.announcement_banner",
                    btnIcon: 'close',
                    support: false
                },
                panelClass: 'error-panel',
                verticalPosition: 'top',
                horizontalPosition: 'center',
            });
            this._session.showAnnouncementBanner = false;
        }
    
    }

    public ngAfterViewInit() {
        this.isLoading = true;

        this.gtm.pageView("profile");
        this.gtm.push();

        const flag = this.getIntroFlag();
        if (!flag) {
            if (window.innerWidth > 639 && window.innerWidth <= 979 && window.innerWidth > window.innerHeight) {
                this.dialog.open(IntroDialogComponent, {
                    width: "100vw",
                    height: "100vh",
                    autoFocus: false,
                    panelClass: "intro-dialog",
                });
            } else if (window.innerWidth > 601) {
                this.dialog.open(IntroDialogComponent, {
                    width: "60vw",
                    height: "80vh",
                    autoFocus: false,
                    panelClass: "intro-dialog",
                });
            } else {
                this.dialog.open(IntroDialogComponent, {
                    width: "80vw",
                    height: "90vh",
                    autoFocus: false,
                    panelClass: "intro-dialog",
                });
            }
            this.setIntroFlag();
        }
    }

    public get userName() {
        const claims = this.oAuth.getIdentityClaims() as ZeissIdToken;
        if (claims == null) {
            return "";
        }
        const base = JSON.parse(claims.ZeissIdBase) as ZeissIdBase;

        return `${base.firstName} ${base.lastName}`;
    }

    public getAvatarImage(sessionId: string) {
        return this.thumbnails.get(sessionId);
    }

    onDelete(sessionId) {
        let d = null;
        if (window.innerWidth < 993) {
            d = this.dialog
                .open(DeleteDialogComponent, {
                    width: "99vw",
                    autoFocus: false,
                })
                .afterClosed();
        } else {
            d = this.dialog
                .open(DeleteDialogComponent, {
                    width: "50vw",
                    autoFocus: false,
                })
                .afterClosed();
        }
        d.subscribe((result) => {
            if (result === true) {
                this._session.deleteAvatarSessionById(sessionId).subscribe(
                    async (next) => {
                        this.snack.openFromComponent(SnackbarComponent, {
                            data: {
                                icon: 'check',
                                heading: "errors.success",
                                message: "pages.profile.delete_success",
                                btnIcon: 'close',
                                support: false
                            },
                            panelClass: 'error-panel',
                            verticalPosition: 'top',
                            horizontalPosition: 'center',
                            duration: 2000
                        });
                        await this._session.fetchSessionsList({headers:{'x-re-cache': 'false'}});

                        this.appInsight.logAction(
                            LogEventTypes[LogEventTypes.delete_avatar_session],
                            {
                                sender: LogEventSender[LogEventSender.User],
                                classification:
                                    LogEventClassification[
                                    LogEventClassification.Info
                                    ],
                                data: {
                                    sessionId,
                                },
                            }
                        );
                    },
                    (error: HttpErrorResponse) => {
                        this.debug.log(error);
                        // eslint-disable-next-line max-len
                        this.snack.openFromComponent(SnackbarComponent, {
                            data: {
                                icon: 'info',
                                heading: "errors.sorry",
                                message: "pages.profile.delete_error",
                                messageParams: {status: `${error.status} ${error.statusText}`},
                                btnIcon: 'close',
                                support: false
                            },
                            panelClass: 'error-panel',
                            verticalPosition: 'top',
                            horizontalPosition: 'center',
                            duration: 2000
                        });
                        this.appInsight.logException(error, {
                            sessionId,
                        });
                    }
                );
            }
        });
    }

    async jumpToViewer(session: AvatarCreationSession, dataUrlFlag?: boolean) {
        try {
            this._session.updateSessionLastAccess(session.id, this.getCurrentDate());
            this._stats.log({
                type: InteractionType.VTO_SESSION,
                sessionId: session.id,
                opticianId: session.opticianId,
                createdAt: new Date(Date.now())
            } as VTOSessionInteraction);
        } catch (error) {
            this.debug.log(error);
        }

        this.gtm.add = {
            [GTMLayer.opticianId]: session.opticianId,
            [GTMLayer.opticianName]: session.opticianName,
        };
        this.gtm.push();

        this.router.navigate([`viewer/${session.id}`]);
    }

    async jumpToFavorites(session) {
        try {
            this._session.updateSessionLastAccess(
                session.id,
                this.getCurrentDate()
            );

            this._stats.log({
                type: InteractionType.VTO_SESSION,
                sessionId: session.id,
                opticianId: session.opticianId,
                createdAt: new Date(Date.now())
            } as VTOSessionInteraction);

        } catch (error) {
            this.debug.log(error);
        }

        this.router.navigate([`gallery/${session.id}`], {
            queryParams: { mode: "favorite", from: "profile" },
        });
    }

    async jumpToGallery(session) {
        try {
            this._session.updateSessionLastAccess(
                session.id,
                this.getCurrentDate()
            );

            this._stats.log({
                type: InteractionType.VTO_SESSION,
                sessionId: session.id,
                opticianId: session.opticianId,
                createdAt: new Date(Date.now())
            } as VTOSessionInteraction);

        } catch (error) {
            this.debug.log(error);
        }

        this.router.navigate([`gallery/`, session.id], {
            queryParams: { mode: "optician", from: "profile" },
        });
    }

    public onContactSupport(session: AvatarCreationSession) {
        this.bottom.open(ContactECPCardComponent, {
            data: { target: "ecp", session },
        });
        this.appInsight.logAction("contact_support", {
            target: "ecp",
            userId: this.userId,
            session,
        });
    }

    private get userId() {
        const claims = this.oAuth.getIdentityClaims() as ZeissIdToken;
        const base = JSON.parse(claims.ZeissIdBase) as ZeissIdBase;

        return base.accountId;
    }

    onCreateSurvey(survey: AvatarCreationSession) {
        if (survey.shareToken) {
            this.router.navigate(
                [`public-survey/${survey.id}/${survey.shareToken}`],
                { queryParamsHandling: "preserve" }
            );
        }
    }

    // 0000px => Small
    // 0640px => Medium
    // 0980px => Large
    // 1200px => XLarge
    // 1800px => Maxed

    onResize() {
        if (window.innerWidth >= 1800) {
            this.displayedColumns = 4;
        } else if (window.innerWidth >= 1200) {
            this.displayedColumns = 4;
        } else if (window.innerWidth >= 900) {
            this.displayedColumns = 3;
        } else if (window.innerWidth >= 640) {
            this.displayedColumns = 2;
        } else if (window.innerWidth >= 0) {
            this.displayedColumns = 1;
        }
    }

    getCurrentDate() {
        return new Date();
    }

    private getIntroFlag(): boolean {
        let flag = false;
        if (
            localStorage.getItem(this.INTRO_FLAG) != null ||
            localStorage.getItem(this.INTRO_FLAG) != undefined
        ) {
            flag =
                localStorage.getItem(this.INTRO_FLAG) == "true" ? true : false;
        }
        return flag;
    }

    private setIntroFlag() {
        localStorage.setItem(this.INTRO_FLAG, "true");
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}
