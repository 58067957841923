<mat-horizontal-stepper [@.disabled]="true" labelPosition="bottom" [linear]="isLinear" #stepper
    class="row size navigation-clear">
    <!-- Step 1 -->
    <mat-step>
        <ng-template matStepLabel> {{ 'components.dialogs.intro.stepper_lable_welcome' | translate }} </ng-template>
        <!-- Style hier entfernen -->
        <div class="verticalCenter">
            <p class="headline"> {{ 'components.dialogs.intro.headline_1' | translate }} </p>
            <p class="subheadline"> {{ 'components.dialogs.intro.addition_headline_1' | translate }} </p>
        </div>
        <div class="col s12 navigation-two">
            <button vcldAutomationId="consr_vis-intro-dialog_skip-intro_btn" mat-button class="col s6" style="margin: 0px;" mat-dialog-close> {{
                'components.dialogs.intro.skip' | translate }} </button>
            <button vcldAutomationId="consr_vis-intro-dialog_next-step_btn" mat-button class="col s6 cyan-text " style="margin: 0px;" matStepperNext> {{
                'components.dialogs.intro.next' | translate }} &gt; </button>
        </div>

    </mat-step>
    <!-- Step 2 -->
    <mat-step>
        <ng-template matStepLabel> {{ 'components.dialogs.intro.stepper_lable_2' | translate }} </ng-template>
        <div>
            <div class="verticalCenter-two">
                <p> {{ 'components.dialogs.intro.explanation_2' | translate }} </p>
                <div class="image">
                    <img [src]="TryOnImage" />
                </div>
            </div>
            <div class="col s12 navigation">
                <button vcldAutomationId="consr_vis-intro-dialog_back_btn" mat-button class="col s6" matStepperPrevious> &lt; {{ 'components.dialogs.intro.back' |
                    translate }}
                </button>
                <button vcldAutomationId="consr_vis-intro-dialog_next_btn" mat-button class="col s6 cyan-text" matStepperNext> {{ 'components.dialogs.intro.next' |
                    translate
                    }} &gt; </button>
            </div>
        </div>
    </mat-step>
    <!-- Step 3 -->
    <mat-step>
        <ng-template matStepLabel> {{ 'components.dialogs.intro.stepper_lable_3' | translate }} </ng-template>
        <div class="verticalCenter-two">
            <p> {{ 'components.dialogs.intro.explanation_3' | translate }} </p>
            <div class="image">
                <img [src]="FavoritesImage" [height]="imageHeight" />
            </div>
        </div>
        <div class="col s12 navigation">
            <button vcldAutomationId="consr_vis-intro-dialog_back_btn" mat-button class="col s6" matStepperPrevious> &lt; {{ 'components.dialogs.intro.back' | translate }}
            </button>
            <button vcldAutomationId="consr_vis-intro-dialog_next_btn" mat-button class="col s6 cyan-text" matStepperNext> {{ 'components.dialogs.intro.next' | translate
                }} &gt; </button>
        </div>
    </mat-step>
    <!-- Step 4 -->
    <mat-step>
        <ng-template matStepLabel> {{ 'components.dialogs.intro.stepper_lable_4' | translate }} </ng-template>
        <div class="verticalCenter-two">
            <p> {{ 'components.dialogs.intro.explanation_4' | translate }} </p>
            <div class="image">
                <img [src]="CatalogImage" [height]="imageHeight" />
            </div>
        </div>
        <div class="col s12 navigation">
            <button vcldAutomationId="consr_vis-intro-dialog_back_btn" mat-button class="col s6" matStepperPrevious> &lt; {{ 'components.dialogs.intro.back' | translate }}
            </button>
            <button vcldAutomationId="consr_vis-intro-dialog_next_btn" mat-button class="col s6 cyan-text" matStepperNext> {{ 'components.dialogs.intro.next' | translate
                }} &gt; </button>
        </div>
    </mat-step>
    <!-- Step 5 -->
    <mat-step>
        <ng-template matStepLabel> {{ 'components.dialogs.intro.stepper_lable_done' | translate }} </ng-template>
        <div class="verticalCenter">
            <p class="headline"> {{ 'components.dialogs.intro.headline_5' | translate }} </p>
            <p class="subheadline"> {{ 'components.dialogs.intro.addition_headline_5' | translate }} </p>
        </div>
        <div class="col s12 navigation-two">
            <button vcldAutomationId="consr_vis-intro-dialog_back_btn" mat-button class="col s6" matStepperPrevious> &lt; {{ 'components.dialogs.intro.back' | translate }}
            </button>
            <button vcldAutomationId="consr_vis-intro-dialog_close_btn" mat-button class="col s6 cyan-text" mat-dialog-close> {{ 'components.buttons.close' | translate }}
            </button>
        </div>
    </mat-step>
    <ng-template matStepperIcon="edit">
        <mat-icon>check</mat-icon>
    </ng-template>
</mat-horizontal-stepper>