import { LensSettings } from '@vision/webviewer/lib/widget';

export enum COATING_TYPE {
  Duravision = 'DURAVISION'
}

export enum TINT_TYPE {
  UniColor = 'UNICOLOR',
  Gradient = 'GRADIENT',
}

export type CoatingObj = {
  id: string;
  coatingGroupId: string;
  catalogCode: string;
  family: string;
  localization: any;
  trafficRestriction: string;
  settings: LensSettings;
  tintRestrictions: string[];
  defaultTint: string;
  thumbnail: string;
};

export type TintObj = {
  id: string;
  coatingGroupId: string;
  catalogCode: string;
  localization: any;
  type: LensType;
  trafficRestriction: string;
  settings: TintSettings;
  thumbnail: string;
  absorptionRestriction: any;
  defaultTint: any;
  family: string;
  vcldTintGroupId: string;
};

export enum LensType {
  Duravision,
  Duravision_Mirror
}

export type TintDefinition = {
  lowestAbsorption: number;
  highestAbsorption: number;
  r: number;
  g: number;
  b: number;
};

export type TintSettings = {
  topColor: TintDefinition;
  bottomColor: TintDefinition;
};

export type TintsAndCoatingResponse = {
  id: string,
  lastUpdate: string,
  tints: TintObj[],
  tintsGroup: TranslationGroup[],
  tintsFamilies: TranslationGroup[],
  coatings: CoatingObj[],
  coatingsGroup: TranslationGroup[]
}

export type TranslationGroup = {
  id: string,
  type?: string
  localization: { [key: string]: string }
}

export type CoatingCatalogGroup = {
  key: string,
  value: CoatingObj[]
}
/* ************************* */
