import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TranslationGroup } from '../../configs/lensSettings.mock';
import { TintsCoatingsService } from '../services/tints-coatings.service';

@Pipe({
  name: 'catalogName',
  pure: true
})
export class CatalogNamePipe implements PipeTransform {

  catalogTranslations:TranslationGroup[] = [];

  constructor(private _tintsCoatings: TintsCoatingsService) {
    this._tintsCoatings.isTintsAndCoatingLoaded$.subscribe(isLoaded => {
      if(isLoaded) {
        const tintGroup = this._tintsCoatings.getTintGroup();
        const tintFamilies = this._tintsCoatings.getTintFamilies();
        const coatingGroup = this._tintsCoatings.getCoatingGroup();
        this.catalogTranslations = [...tintGroup, ...coatingGroup, ...tintFamilies];
      }
    });
  }
  
  transform(lang: string, value: string): string {
    if (!value) return '';

    if (!lang) return value;

    if (!this.catalogTranslations || this.catalogTranslations.length <= 0) return value;

    const catalog = this.catalogTranslations.filter(ctlg => ctlg.id === value)[0];
  
    if (catalog) {
      if (catalog.localization[lang]) return catalog.localization[lang];
      else if (catalog.localization['en-US']) return catalog.localization['en-US'];
    } 

    return value; 
  }

}
