import { Injectable } from "@angular/core";
import { OAuthService } from "angular-oauth2-oidc";

import { FPSTransformation } from "../models/fps.transformation.model";

import { ConsumerAppEnvironment as environment } from "visenvironment";
import {
    VTO,
    VTO_InitializeOptions,
    AvatarEndpoints,
    BackgroundOptions,
    BendAxes,
    GlassesEndpoints,
    GlassesTransformation,
    CameraSettings,
    ResumeOptions,
} from "@vision/webviewer/lib/vto";
import { LensSettings } from "@vision/webviewer/lib/widget";

import { BehaviorSubject, Observable } from "rxjs";
import { AvatarSource } from "../models/avatarcreationsession";
import { SessionService } from "./session.service";
import { TintsCoatingsService } from "./tints-coatings.service";
import { DataService,BinaryType } from "./data.service";

@Injectable({ providedIn: "root" })
export class ViewerFactoryService {
    constructor(
        private oAuth: OAuthService,
        public _session: SessionService,
        public _tintsCoatings: TintsCoatingsService,
        private _datacache:DataService
    ) {}

    private readonly baseAvatarUrl = environment.connectivity.esbBaseUrl;
    private readonly baseFrameUrl =
        environment.connectivity.esbBaseUrl + "/frame";

    private readonly mockCustomerNo = "620681";

    private isSafari = () => {
        if (typeof navigator !== "undefined") {
            const ua = navigator.userAgent;
            const check = ua.includes("AppleWebKit") && !ua.includes("Windows");
            // Get version-number from user agent
            const version = ua.match(/Version\/([0-9]+)/);
            let versionCheck = false;
            if(version) {
                try {
                    const versionNumber = parseInt(version[0].split('/')[1]);
                    versionCheck = versionNumber < 16;
                } catch(e) {

                }
            }

            return check && versionCheck;
        }

        return true;
    };

    private _vtoInstance: VTO;

    public isInitialized$: BehaviorSubject<boolean> = new BehaviorSubject(
        false
    );

    public isPaused$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    // Returns the new Widget instance
    public createNewViewerWidget(): VTO {
        if (!this._vtoInstance) {
            this._vtoInstance = new VTO();
        }
        return this._vtoInstance;
    }

    public createNewInstance(): VTO {
        return new VTO();
    }

    // Returns list of available pre-configured viewer options
    public createNewViewerInitOptions(
        viewerContainer: string,
        isDebugMode?: boolean,
        avatar?: AvatarEndpoints
    ): VTO_InitializeOptions {
        if (!this.oAuth.hasValidIdToken()) {
            throw new Error(
                "Unable to create new Viewer-Instance! - Error: Invalid ID-Token"
            );
        }

        return {
            id: viewerContainer,
            assetsPath: "/assets/utils",
            developerCamera: false,
            avatar,
            lensSettings: this._tintsCoatings.getlensSettingsByCatalogCode(
                "duravision_platinum"
            ),
            antialiasing: !this.isSafari(),
            annotationsVisible: false,
            showShadow: true,
            headers: {
                Authorization: "Bearer " + this.oAuth.getIdToken()
            },
            cameraSettings: this.createCameraSettings(),
            background: {
                mode: "shader",
                color: { r: 1, g: 1, b: 1},
                // image: "assets/viewer-backgrounds/avatar-background.png",
                // mode: "image",
                // color: { r: 1.0, g: 1.0, b: 1.0 },
                // image: "/assets/viewer-backgrounds/avatar-background.png",
                // initial bg will be replaced once load process is done,
                // but we need an image or else an error occurs
            } as BackgroundOptions,
            lightSetup : "zeiss"
        };
    }

    public createViewerResumeOptions(
        viewerContainer: string,
        isDebugMode?: boolean,
        avatar?: AvatarEndpoints
    ): ResumeOptions {
        if (!this.oAuth.hasValidIdToken()) {
            throw new Error(
                "Unable to create new Viewer-Instance! - Error: Invalid ID-Token"
            );
        }

        return {
            id: viewerContainer,
            avatar,
            cameraSettings: this.createCameraSettings(),
        };
    }

    public createCameraSettings(): CameraSettings {

        const cameraSettingsVisucloud: CameraSettings = {
            avatar: {
                distance: {
                    min: 0.89,
                    max: 1.6,
                    initial: 1.13
                },
            },
            glasses: {
                distance: {
                    min: 0.41,
                    max: 0.76,
                    initial: 0.76
                }
            },

        };

        const cameraSettingsCameron: CameraSettings = {
            avatar: {
                distance: {
                    min: 0.36,
                    max: 0.72,
                    initial: 0.4523,
                },
                yaw: {
                    min: -50,
                    max: 50,
                    //initial: 25,
                    initial: 20,
                },
                pitch: {
                    min: -25,
                    max: 10,
                    initial: 1,
                },
                fov: 60,
                orbitCenter: {
                    x: 0.0,
                    y: 0.02,
                    //z: -0.09,
                    z: -0.075,
                },
                autoZoom: {
                    exponent: 1.3,
                    scale: {
                        y: 0.01,
                        //z: 0.035,
                        z: 0.0975,
                        
                    },
                    factor: 0.2,
                },
            },
            glasses: {
                distance: {
                    min: 0.5,
                    max: 0.76,
                    initial: 0.76,
                },
            },
        };

        if(this._session.selectedSession$.getValue().source === AvatarSource.CAMERON) return cameraSettingsCameron; 

        return cameraSettingsVisucloud;
    }

    public createCompareCameraSettings(
        min: number,
        max: number
    ): CameraSettings {
        const cameraSettingsCameron: CameraSettings = {
            avatar: {
                distance: {
                    min: 0.36,
                    max: 0.72,
                    initial: 0.4523,
                },
                yaw: {
                    min: -50,
                    max: 50,
                    //initial: 25,
                    initial: 20,
                },                
                fov: 60,
                orbitCenter: {
                    x: 0.0,
                    y: 0.02,
                     //z: -0.09,
                     z: -0.075,
                },
                autoZoom: {
                    exponent: 1.3,
                    scale: {
                        y: 0.01,
                         //z: 0.035,
                         z: 0.0975,
                    },
                    //factor: 0.2,
                    factor: 0.5,
                },
            },
            glasses: {
                distance: {
                    min: 0.5,
                    max: 0.76,
                    initial: 0.76,
                },
            },
        };
        const cameraSettingsVisucloud = {
            avatar: {
                distance: {
                    min: 1,
                    max: 1.6,
                    initial: 1.13,
                },
            },
            glasses: {
                distance: {
                    min,
                    max,
                    initial: max,
                },
            },
        };

        if (this._session.selectedSession$.getValue().source === AvatarSource.CAMERON) return cameraSettingsCameron;
        return cameraSettingsVisucloud;
    }

    // Returns new ColorCoordinations for the Veiwer-Background (internal | webGL)
    public generateBackgroundOptions(
        red: number,
        green: number,
        blue: number
    ): BackgroundOptions {
        return {
            color: {
                r: red,
                g: green,
                b: blue,
            },
            mode: "solid",
            vignette: 0.7,
        };
    }

    // Returns the viewer Bend-Axes
    public getBendAxesOptions(): BendAxes {
        return "xy";
    }

    // Returns GlassEndpoints with only frame (model) and metadata
    public createSimplyfiedGlassOptions(frameId?: string): GlassesEndpoints {
        return {
            frame: `${this.baseFrameUrl}/${this.mockCustomerNo}/${frameId}/Model.gltf`,
            metadata: `${this.baseFrameUrl}/${this.mockCustomerNo}/${frameId}/Annotations.json`,
        };
    }

    public async createDetailedVariantGlassOptions(
        frameId: string,
        variantFrameId: string
    ): Promise<GlassesEndpoints> {
        return {
            frame: {
                /* mesh: `${this.baseFrameUrl}/${this.mockCustomerNo}/${frameId}/Model.obj`, */
                mesh: await this._datacache.getBinary(BinaryType.FRAME,frameId),
                materialLibrary: `${this.baseFrameUrl}/${this.mockCustomerNo}/${variantFrameId}/Model.mtl`,
                fileFormat: "obj",
            },
            metadata: `${this.baseFrameUrl}/${this.mockCustomerNo}/${variantFrameId}/Annotation.json`,
            textures: {
                albedo: `${this.baseFrameUrl}/${this.mockCustomerNo}/${variantFrameId}/Albedo.jpg`,
                normal: `${this.baseFrameUrl}/${this.mockCustomerNo}/${variantFrameId}/Normal.png`,
                orm: `${this.baseFrameUrl}/${this.mockCustomerNo}/${variantFrameId}/ORM.png`,
            },
            lenses: `${this.baseFrameUrl}/${this.mockCustomerNo}/${variantFrameId}/LensesModel.glb`,
        };
    }
    // Returns GlassEndpoints with full dataset (Albedo, Texture, SMR, etc...)
    public async createDetailedGlassOptionsESB(
        frameId?: string
    ): Promise<GlassesEndpoints> {
        if (frameId.startsWith("ffffffff")) {
            return {
                frame: {
                    mesh: `${this.baseFrameUrl}/${this.mockCustomerNo}/${frameId}/Model.gltf`,
                    materialLibrary: `${this.baseFrameUrl}/${this.mockCustomerNo}/${frameId}/Model.mtl`,
                    fileFormat: "gltf",
                },
                metadata: `${this.baseFrameUrl}/${this.mockCustomerNo}/${frameId}/Annotation.json`,
                textures: {
                    albedo: `${this.baseFrameUrl}/${this.mockCustomerNo}/${frameId}/Albedo.jpg`,
                    normal: `${this.baseFrameUrl}/${this.mockCustomerNo}/${frameId}/Normal.png`,
                    orm: `${this.baseFrameUrl}/${this.mockCustomerNo}/${frameId}/ORM.png`,
                },
                lenses: `${this.baseFrameUrl}/${this.mockCustomerNo}/${frameId}/LensesModel.glb`,
            };
        }
        return {
            frame: {
                mesh: `${this.baseFrameUrl}/${this.mockCustomerNo}/${frameId}/Model.gltf`,
                // mesh: await this.cache.getBinaryUrl(frameId, BinaryType.FRAME, false),
                materialLibrary: `${this.baseFrameUrl}/${this.mockCustomerNo}/${frameId}/Model.mtl`,
                fileFormat: "gltf",
                binary: `${this.baseFrameUrl}/${this.mockCustomerNo}/${frameId}/Model.bin`
            },
            metadata: `${this.baseFrameUrl}/${this.mockCustomerNo}/${frameId}/Annotation.json`,
            textures: {
                albedo: `${this.baseFrameUrl}/${this.mockCustomerNo}/${frameId}/Albedo.jpg`,
                normal: `${this.baseFrameUrl}/${this.mockCustomerNo}/${frameId}/Normal.png`,
                orm: `${this.baseFrameUrl}/${this.mockCustomerNo}/${frameId}/ORM.png`,
            },
            lenses: `${this.baseFrameUrl}/${this.mockCustomerNo}/${frameId}/LensesModel.glb`,
        };
    }
  
    private async loadFrameAssets(frameId: string) {
        const frameTask = this._datacache.getBinary(BinaryType.FRAME, frameId, true);
        const materialLibraryTask = this._datacache.getBinary(BinaryType.MATERIAL_LIBRARY, frameId, true);
        const binaryTask = this._datacache.getBinary(BinaryType.MODEL, frameId, true);

        const metadataTask = this._datacache.getBinary(BinaryType.ANNOTATION, frameId, true);

        const albedoTask = this._datacache.getBinary(BinaryType.ALBEDO, frameId, true);
        const normalFileTask = this._datacache.getBinary(BinaryType.NORMAL, frameId, true);
        const ormTask = this._datacache.getBinary(BinaryType.ORM, frameId, true);

        const lensModelTask = this._datacache.getBinary(BinaryType.LENSES_MODEL, frameId, true);

        const result = [await frameTask, await materialLibraryTask, await binaryTask,
            await metadataTask, await albedoTask, await normalFileTask, await ormTask, await lensModelTask]

        return result;
    }

    public async createDetailedGlassOptionsParallel(frameId?: string): Promise<GlassesEndpoints> {
        const start = performance.now();
        const result = await this.loadFrameAssets(frameId);

        const glassOptions = {
            frame: {
                mesh: result[0],
                fileFormat: 'gltf',
                materialLibrary: result[1],
                binary: result[2],
            },
            metadata:  result[3],
            textures: {
                albedo: result[4],
                normal: result[5],
                orm: result[6]
            },
            lenses: {
                mesh: result[7],
                fileFormat:'glb'
            }
        } as GlassesEndpoints;

        const time = performance.now() - start;

        console.log('[AdminVtoService] Time taken to load frame assets: ', time);

        return glassOptions;
    }

    // Returns GlassEndpoints with full dataset from cdn(Albedo, Texture, SMR, etc...)
    public async createDetailedGlassOptions(frameId?: string): Promise<GlassesEndpoints> {
        return {
            frame: {
                mesh: await this._datacache.getBinary(BinaryType.FRAME,frameId,true),
                fileFormat: 'gltf',
                materialLibrary: await this._datacache.getBinary(BinaryType.MATERIAL_LIBRARY,frameId,true),
                binary: await this._datacache.getBinary( BinaryType.MODEL,frameId,true),
            },
            metadata:  await this._datacache.getBinary( BinaryType.ANNOTATION, frameId,true),
            textures: {
                albedo: await this._datacache.getBinary(BinaryType.ALBEDO, frameId,true),
                normal: await this._datacache.getBinary( BinaryType.NORMAL, frameId,true),
                orm: await this._datacache.getBinary( BinaryType.ORM, frameId,true)
            },
            lenses: {
                mesh: await this._datacache.getBinary( BinaryType.LENSES_MODEL,frameId,true),
                fileFormat:'glb'
            }
        }
    }
     

    // Returns Configuration for downloading Avatar (viewer internal)
    public createAvatarOptions(sessionIdToUse: string): AvatarEndpoints {
        return {
            metadata: `${this.baseAvatarUrl}/${sessionIdToUse}/MetaData`,
            mesh: {
                mesh: `${this.baseAvatarUrl}/${sessionIdToUse}/Mesh`,
                fileFormat: "obj",
            },
            texture: `${this.baseAvatarUrl}/${sessionIdToUse}/Texture`,
        };
    }

    public async createAvatarOptionsAsync(
        sessionIdToUse: string
    ): Promise<AvatarEndpoints> {
        return {
            metadata: await this._datacache.getBinary(               
                BinaryType.AVATAR_METADATA,
                sessionIdToUse
            ),
            mesh: {
                mesh: await this._datacache.getBinary(                    
                    BinaryType.AVATAR,
                    sessionIdToUse
                ),
                fileFormat: "obj",
            },
            texture: await this._datacache.getBinary(                
                BinaryType.AVATAR_TEXTURE,
                sessionIdToUse
            ),
        };
    }
   
    // Returns a Matrix4 from the given Transformation
    public convertFPSTransformToMatrix(
        transformation: FPSTransformation
    ): GlassesTransformation {
        if (!transformation) {
            return null;
        }

        return {
            frame: [
                transformation.A11,
                transformation.A21,
                transformation.A31,
                transformation.A41,
                transformation.A12,
                transformation.A22,
                transformation.A32,
                transformation.A42,
                transformation.A13,
                transformation.A23,
                transformation.A33,
                transformation.A43,
                transformation.A14,
                transformation.A24,
                transformation.A34,
                transformation.A44,
            ],
        };
    }
}
