import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, shareReplay } from 'rxjs/operators';
import { GLASS_TYPE_ELIGIBILITY } from '../../configs/constant.flags';
import { DebugService } from '../../services/debug.service';
import { FeatureFlag } from '../models/ecp-settings.model';
import { Frame } from '../models/frame.model';
import { FrameFilter, FrameMaterial } from '../models/framefilter';
import { FrameService } from './frame.service';
import { SessionService } from './session.service';
import { ViewerService } from './viewer.service';

@Injectable({ providedIn: 'root' })
export class FrameFilterService {

    public priceFilterMinValue$: Observable<number>;
    public priceFilterMaxValue$: Observable<number>;

    constructor(
        private debug: DebugService,
        public _frames:FrameService,
        public _session: SessionService,
        public _viewer: ViewerService
    ) {
        this.priceFilterMinValue$ = this._frames.frsSortedFrameCatalogue$.pipe(
            map(frames => {
                let value = frames ? Math.min(...frames.map((f) => f.retailPrice)) : 0;
                return this.getValidValue(value);
            })
        );

        this.priceFilterMaxValue$ = this._frames.frsSortedFrameCatalogue$.pipe(
            map(frames => {
                let value = frames ? Math.max(...frames.map((f) => f.retailPrice)) : 0;
                return this.getValidValue(value);
            })
        );
    }

    public filters$ = new BehaviorSubject<FrameFilter>(new FrameFilter());
    public filtersForPreview$ = new BehaviorSubject<FrameFilter>(new FrameFilter());

    public selectedFilters: FrameFilter = new FrameFilter();

    public highestPriceAvailable = 10000;

    private getValidValue(price: any): number {
        const value = isNaN(price) ? 0 : price;
        return value;
    }

    public get filterCount() {
        if (this.selectedFilters) {
            return Object.entries(this.selectedFilters).filter(([key, value]) => value !== null).length;
        }
        return 0;
    }

    public get minimumPriceValue(): number {
        return this.selectedFilters && this.selectedFilters.price
            ? this.selectedFilters.price[0]
            : 0;
    }

    public get maximumPriceValue(): number {
        return this.selectedFilters && this.selectedFilters.price
            ? this.selectedFilters.price[1]
            : this.highestPriceAvailable;
    }

    /* 
        initially get filters for selected session
    */
    public async initFilters(sessionId: string) {
        this.selectedFilters = this.getPersistedFilters()?.get(sessionId);
        if (this.selectedFilters) {           
           const settings = await this._viewer.ecpSettings$.getValue();         
            if(!settings.pricingEnabled){
                this.selectedFilters.price = null;
            }
            
            /*
                Update old filter material options to new scheme
            */
            if(this.selectedFilters.material){
                for(let i = 0; i < this.selectedFilters.material.length; i++) {
                    if(typeof(this.selectedFilters.material[i]) !== 'number') {
                        let material = this.selectedFilters.material[i].toString().toUpperCase();
                        this.selectedFilters.material[i]=(FrameMaterial[material]);
                     }  
                }
            }
            
            this.filters$.next(this.selectedFilters);
            this.filtersForPreview$.next(this.selectedFilters);
        }
    }

    /* 
        Get all saved filters from local storage of all sessions
    */
    public getPersistedFilters(): Map<string, FrameFilter> {
        return JSON.parse(
            localStorage.getItem("filterPreferences"),
            function (key, value) {
                if (typeof value === "object" && value !== null) {
                    if (value.dataType === "Map") {
                        return new Map(value.value);
                    }
                }
                return value;
            }
        ) as Map<string, FrameFilter>;
    }

    /* 
        Save all filters to local storage
    */
    public setPersistedFilters(persistedFilters) {
        localStorage.setItem('filterPreferences', JSON.stringify(persistedFilters, function (key, value) {
            const originalObject = this[key];
            if (originalObject instanceof Map) {
                return {
                    dataType: 'Map',
                    value: [...originalObject],
                };
            } else {
                return value;
            }
        }));
    }

    public applyFilters(sessionId: string) {

        this.filters$.next(this.selectedFilters);
        let persistedFilters = this.getPersistedFilters();
        if (!(persistedFilters instanceof Map)) {
            persistedFilters = new Map();
        }
        // Reset the price filter before saving because it is a flagged feature and may be disabled by an optician.
        const filtersForSave = Object.assign({}, this.selectedFilters);

        persistedFilters.set(sessionId, filtersForSave);
        this.setPersistedFilters(persistedFilters);

    }

    /* 
        Clear the filters for selected session
    */
    public clearFilters(sessionId: string) {
        const filter = new FrameFilter();
        this.selectedFilters = filter;
        this.filtersForPreview$.next(filter);
        this.filters$.next(filter);

        let persistedFilters = this.getPersistedFilters();
        if (!(persistedFilters instanceof Map)) {
            persistedFilters = new Map();
        }
        persistedFilters.delete(sessionId);
        this.setPersistedFilters(persistedFilters);
    }

    /* 
        Filter frames
    */
    public filterFrames(filters: FrameFilter, frames: Frame[]): Frame[] {
        let f = frames;
        this.debug.log(filters["type"]);

        for (const key in filters) {
            if (filters[key] != null) {
                switch (key) {
                    case "type":
                        // f = f.filter(frame => filters.type == 'sun' ? frame.color.includes('SUN') : !frame.color.includes('SUN'));
                        f = f.filter((frame) =>
                            filters.type.length == 2
                                ? true
                                : filters.type[0] == "sun"
                                    ? this.isSunglass(frame)
                                    : !this.isSunglass(frame)
                        );
                        break;
                    case "brand":
                        f = f.filter((frame) =>
                            filters.brand.includes(frame.brand)
                        );
                        break;
                    case "material":
                        f = f.filter((frame) => {
                            if(filters.material.includes(FrameMaterial.OTHERS) && filters.material.length === 1) return ( FrameMaterial[frame.material]===undefined || frame.material===null );
                            if(!(filters.material.includes(FrameMaterial.OTHERS))) return (filters.material.includes(FrameMaterial[frame.material]));
                            if(filters.material.includes(FrameMaterial.OTHERS) && filters.material.length > 1) return ( FrameMaterial[frame.material]===undefined || frame.material===null || filters.material.includes(FrameMaterial[frame.material]));
                        });
                        break;
                    case "shapeType":
                        f = f.filter((frame) =>
                            filters.shapeType.includes(frame.shapeType)
                        );
                        break;
                    case "rimType":
                        f = f.filter((frame) =>
                            filters.rimType.includes(frame.rimType)
                        );
                        break;
                    case "price":
                        break;
                    case "style":
                        f = f.filter((frame) =>
                            filters.style.includes(frame.style)
                        );
                        break;
                    case "targetGroup":
                        f = f.filter((frame) => {
                            if (filters.targetGroup.includes('UNISEX')) return filters.targetGroup.includes(frame.targetGroup) || frame.targetGroup == null;
                            return filters.targetGroup.includes(frame.targetGroup);
                        });
                        break;
                }
            }
        }
        if (filters.isColorful) {
            f = f.filter((frame) => frame.isColorful);
        } else if (filters.isDark) {
            f = f.filter((frame) => frame.isDark);
        } else if (filters.isLight) {
            f = f.filter((frame) => frame.isLight);
        }

        f = f?.filter(
            (frame) =>
                frame.retailPrice >= this.minimumPriceValue &&
                frame.retailPrice <= this.maximumPriceValue
        );
        return f;

    }

    /* 
        Functions for UI 
        to select filters
        to determine selection state of filter
    */
    public selectFilter<K extends keyof FrameFilter>(type: K, value: FrameFilter[K]) {
        const filters = Object.assign({}, this.selectedFilters);
        const v = value as string;

        if (!Array.isArray(filters[type])) {
            filters[type as string] = [];
        }

        const index = filters[type as string].findIndex((e) => {
            if (typeof(e) == 'number') return e === FrameMaterial[v];
            return e === v;
        });
        if (index === -1) {
            if(type === 'material') {
                filters[type as string].push(FrameMaterial[v]);
            }else{
                filters[type as string].push(v);
            }
        } else {
            filters[type as string].splice(index, 1);
            if (filters[type as string].length === 0) {
                filters[type as string] = null;
            }
        }

        this.selectedFilters = filters;
        this.filtersForPreview$.next(filters);
    }

    public selectColorFilter(color: "isColorful" | "isDark" | "isLight") {
        const filters = this.filtersForPreview$.getValue();

        if (filters[color]) {
            filters[color] = null;
        } else {
            filters.isColorful = null;
            filters.isDark = null;
            filters.isLight = null;
            filters[color] = true;
        }
        
        this.selectedFilters = filters;
        this.filtersForPreview$.next(filters);
    }

    public isFilterSelected<K extends keyof FrameFilter>(type: K, value: FrameFilter[K]): boolean {
        if (this.selectedFilters) {
            return Array.isArray(this.selectedFilters[type as string])
                ? this.selectedFilters[type as string].includes(value as string)
                : false;
        }
        return false;
    }

    public isMaterialSelected(material: string): boolean {
        if(this.selectedFilters?.material){
            return this.selectedFilters.material.includes(FrameMaterial[material]);
        }
        return false;
    }

    public isColorFilterSelected(color: "isColorful" | "isDark" | "isLight"): boolean {
        if (this.selectedFilters) {
            return this.selectedFilters[color];
        }
        return false;
    }

    public isBrandSelected(brand: string): boolean {
        if (this.selectedFilters) {
            return (this.selectedFilters.brand || []).includes(brand);
        }
        return false;
    }

    /*
     * find if frame is of sunglass type using 'glassTypeEligibility' property.
     * if 'glassTypeEligibility' does not have valid value then check against color of frame.
     */
    private isSunglass(frame: Frame) {
        if (frame?.glassTypeEligibility) {
            return frame?.glassTypeEligibility == GLASS_TYPE_ELIGIBILITY.SUN;
        } else {
            const suncheck = /(SUN$)|(SUN.(\d?))$/gm;
            return suncheck.test(frame?.color);
        }
    }

    public removeTemporaryFilter(sessionId: string) {
        this.selectedFilters = this.getPersistedFilters()?.get(sessionId);
        if(!this.selectedFilters) {
            this.selectedFilters = new FrameFilter();
        }
        this.filters$.next(this.selectedFilters);
        this.filtersForPreview$.next(this.selectedFilters);
    }

}