import { FavoriteFrame } from './../../models/avatarcreationsession';
import {
    Component,
    OnInit,
    ElementRef,
    ViewChild,
    AfterViewInit,
    OnDestroy,
    ChangeDetectorRef,
    ChangeDetectionStrategy,
    Renderer2,
} from "@angular/core";
// import { VTOStateService } from '../../services/vto.state.service';
import { of, Observable, BehaviorSubject, combineLatest, fromEvent, Subscription, ReplaySubject } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { tap, map, first, mergeMap, finalize, startWith, shareReplay, switchMap, debounceTime, filter, distinctUntilChanged } from 'rxjs/operators';
import { Frame, FrameAvailability } from '../../models/frame.model';
import { ChangeContext, Options } from '@angular-slider/ngx-slider';
import { ApplicationInsightsService } from '../../../services/applicationInsights.Service';
import { MatDrawer } from '@angular/material/sidenav';
import { ConsumerAppEnvironment as environment } from 'visenvironment';
import { GoogleTagManagerService } from '../../services/gtm.service';
import { DebugService } from '../../../services/debug.service';
import { FeatureFlag } from '../../models/ecp-settings.model';
import { TranslateService } from '@ngx-translate/core';
import { FilterItems } from '../../models/filterItems';
import { ScrollService } from '../../services/scroll.service';
import { FrameFilterService } from '../../services/frame-filter.service';
import { DataService, DataType,BinaryType } from '../../services/data.service';
import { SessionService } from '../../services/session.service';
import { TintsCoatingsService } from '../../services/tints-coatings.service';
import { ViewerService } from '../../services/viewer.service';
import { FrameService } from '../../services/frame.service';
import { IPageInfo, VirtualScrollerComponent } from '@iharbeck/ngx-virtual-scroller';


@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: "vis-gallery-page",
    templateUrl: "./gallery.page.component.html",
    styleUrls: ["./gallery.page.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GalleryPageComponent implements OnInit, AfterViewInit, OnDestroy {
    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private appInsight: ApplicationInsightsService,
        private gtm: GoogleTagManagerService,
        private debug: DebugService,
        private translate: TranslateService,
        public cd: ChangeDetectorRef,
        private _scrollService: ScrollService,
        public frameFilter: FrameFilterService,
        private _datacache:DataService,
        public _session: SessionService,
        private _tintsCoatings: TintsCoatingsService,
        public _viewer:ViewerService,
        public _frames: FrameService,
        private renderer: Renderer2,
        private element: ElementRef
    ) { }

    public isProdMode = !!environment.production;

    // Debug Flag (can be turned on in the localStorage)
    public isDebugMode = false;

    @ViewChild("inventoryScroller")  private virtualScroller: VirtualScrollerComponent; 
    @ViewChild("recScroller")  private virtualScrollerRecommendations: VirtualScrollerComponent; 

    @ViewChild("filterMenu")
    public drawerElement: MatDrawer;

    @ViewChild('frameWrapper', { static: true })
    // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
    private _frameWrapperHost: ElementRef;
    private _frameWrapperContainer: HTMLDivElement;

    // Number of Frames to fetch on first time
    // URL-Query Parameter
    public sourceView: "profile" | "viewer";
    public mode: "favorite" | "optician";

    // Number of Frames to fetch
    public preloadFrames = 24;

    // Number of frames to preload on scroll agent
    private preloadIncrement = 16;

    private frameSearch$: BehaviorSubject<string> = new BehaviorSubject(null);

    public frameArrayLength$: BehaviorSubject<number> = new BehaviorSubject(0);

    // Delay for showing content in seconds
    public loadingDelay = 6;

    public isLoading$ = new BehaviorSubject(true);

    public compare = false;
    public frameCompareList: Array<FavoriteFrame> = [];

    public changeTrigger$: ReplaySubject<string> = new ReplaySubject(1);

    public sessionId;

    public toCompare = [];

    public frames$: Observable<Frame[]>;
    public favoritedFrames$;

    public framesToPreload$: BehaviorSubject<number> =
        new BehaviorSubject<number>(null);

    public favoriteCount$: Observable<number>;

    public filteredFramesCount$: Observable<number>;

    public favoritesSelected$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(false);

    private frameSource$: Observable<Frame[]>;

    public dmtFrames$: Observable<Frame[]>;

    public priceThreshold = 100;

    public priceFilterSubscription;

    public listActive = true;

    private isScrollToAccessed = false;

    private jumpScrollPosition = 0;

    private jumpInDmtRecFlag = "false";

    // More details: https://angular-slider.github.io/
    public sliderOptions: Options = {
        floor: 0,
        ceil: this.frameFilter.highestPriceAvailable,
        disabled: false,
        hideLimitLabels: false,
        hidePointerLabels: false,
        // step: 200,
        translate: (value: number): string => {
            return `${value}EUR`;
        },
    };

    public filterResultCount = 200;

    public showLogo: boolean;

    public get screenWidth() {
        return window.innerWidth;
    }

    public readonly filterTypeList: FilterItems[] = [
        { filterText: 'pages.gallery.filters.spectacle_sun', filterIcon: 'sun-frame', filterType: 'sun' },
        { filterText: 'pages.gallery.filters.spectacle_optical', filterIcon: 'optical-frame', filterType: 'optical' }
    ];

    public readonly filterShapeList: FilterItems[] = [
        { filterText: 'pages.gallery.filters.square', filterIcon: 'square-frame', filterType: 'SQUARE' },
        { filterText: 'pages.gallery.filters.rectangular', filterIcon: 'rect-frame', filterType: 'RECTANGLE' },
        { filterText: 'pages.gallery.filters.round', filterIcon: 'round-frame', filterType: 'ROUND' },
        { filterText: 'pages.gallery.filters.pilot', filterIcon: 'pilot-frame', filterType: 'PILOT' },
        { filterText: 'pages.gallery.filters.butterfly', filterIcon: 'butterfly-frame', filterType: 'BUTTERFLY_CATEYE' },
        { filterText: 'pages.gallery.filters.panto', filterIcon: 'panto-frame', filterType: 'PANTO' }
    ];

    public readonly filterRimList: FilterItems[] = [
        { filterText: 'pages.gallery.filters.fullrim', filterIcon: 'fullrim-frame', filterType: 'FULLRIM' },
        { filterText: 'pages.gallery.filters.nylor', filterIcon: 'nylor-frame', filterType: 'NYLOR' },
        { filterText: 'pages.gallery.filters.rimless', filterIcon: 'rimless-frame', filterType: 'RIMLESS' }
    ];

    public readonly filterColorList: FilterItems[] = [
        { filterText: 'pages.gallery.filters.colorful', filterIcon: 'color-frame', filterType: 'isColorful' },
        { filterText: 'pages.gallery.filters.dark', filterIcon: 'dark-frame', filterType: 'isDark' },
        { filterText: 'pages.gallery.filters.light', filterIcon: 'light-frame', filterType: 'isLight' }
    ];

    public readonly filterLifestyleList: FilterItems[] = [
        { filterText: 'pages.gallery.filters.elegant', filterIcon: 'elegant-frame', filterType: 'ELEGANT' },
        { filterText: 'pages.gallery.filters.sporty', filterIcon: 'sporty-frame', filterType: 'SPORTY' },
        { filterText: 'pages.gallery.filters.classic', filterIcon: 'classic-frame', filterType: 'CLASSIC' },
        { filterText: 'pages.gallery.filters.urban', filterIcon: 'urban-frame', filterType: 'URBAN' },
        { filterText: 'pages.gallery.filters.stylish', filterIcon: 'stylish-frame', filterType: 'STYLISH' }
    ];

    public readonly filterMaterialList: FilterItems[] = [
        { filterText: 'pages.gallery.filters.wood', filterIcon: 'wood-frame', filterType: 'WOOD' },
        { filterText: 'pages.gallery.filters.plastic', filterIcon: 'plastic-frame', filterType: 'PLASTIC' },
        { filterText: 'pages.gallery.filters.metal', filterIcon: 'metal-frame', filterType: 'METAL' },
        { filterText: 'pages.gallery.filters.titanium', filterIcon: 'titanium-frame', filterType: 'TITANIUM' },
        { filterText: 'pages.gallery.filters.combination', filterIcon: 'combination-frame', filterType: 'COMBINATION' },
    ];

    public readonly filterTargetList: FilterItems[] = [
        { filterText: 'pages.gallery.filters.female', filterIcon: 'female-frame', filterType: 'FEMALE' },
        { filterText: 'pages.gallery.filters.male', filterIcon: 'male-frame', filterType: 'MALE' },
        { filterText: 'pages.gallery.filters.unisex', filterIcon: 'unisex-frame', filterType: 'UNISEX' }
    ];

    public get itemSize(): number {
        return 6;
    }

    public searchFocus$: ReplaySubject<boolean> = new ReplaySubject(1);

    public lastScrollEndIndex: number = -1;

    private latestComparedFrame: any;

    private isIntervalRunning: boolean = false;

    async ngOnInit(): Promise<void> {
        await this._session.fetchSessionsList();
        this.sessionId = this.route.snapshot.params.sessionId;

        try{
            await this._session.setSession(this.sessionId);
        } catch(error){
            this.router.navigate(['/profile']);
            return;
        }

        let scrollToIndex;
        if(this.route.snapshot.queryParams.scrollPosition){
            scrollToIndex = parseInt(this.route.snapshot.queryParams.endIndex);
            this.jumpScrollPosition = parseInt(this.route.snapshot.queryParams.scrollPosition);            
        }
        if(this.route.snapshot.queryParams.dmt)
            this.jumpInDmtRecFlag = this.route.snapshot.queryParams.dmt;
        if(this.route.snapshot.queryParams.list)
            this.listActive = this.route.snapshot.queryParams.list == "true" ?true:false;
            
        this.framesToPreload$.next(this.preloadFrames);
        

        this.sourceView = this.route.snapshot.queryParams.from;
        this.mode = this.route.snapshot.queryParams.mode
            ? this.route.snapshot.queryParams.mode
            : "optician";

        
        await this._viewer.initEcpSettings();
        await this._frames.initAllFrames(this._session.selectedSession$.getValue().opticianCustomerNumber);
        await this.frameFilter.initFilters(this.sessionId);
        await this._tintsCoatings.getTintsAndCoatings();
        
        
        const initRecommendations = this._frames.initRecommendedFrames(this.sessionId);
        const initCampaignRcommendations =  this._frames.initCampaignRecommendations();
        const initFavoritedFrames = this._frames.initFavoritedFrames();
        const result = [await initRecommendations,await initCampaignRcommendations, await initFavoritedFrames];

        this.appInsight.logPageView("gallery", window.location.href);

        if (this.mode === "favorite") {
            this.favoritesSelected$.next(true);
            this.gtm.pageView("favorite-gallery");
        } else {
            this.favoritesSelected$.next(false);
            this.gtm.pageView("optician-inventory");
        }

        this.frameSource$ = this.favoritesSelected$.pipe(
            switchMap((favoritesSelected) => {
                const source = favoritesSelected ? this._frames.favoritedFrames$ : combineLatest([this._frames.frsSortedFrameCatalogue$, this.frameFilter.filters$, this.frameSearch$]).pipe(
                    map(([frames, filters, search]) => {
                        if (!search) return this.frameFilter.filterFrames(filters, frames).filter((f) => f.availabilityStatus ? parseInt(FrameAvailability[f.availabilityStatus]) == FrameAvailability.AVAILABLE : false)

                        let searchQuery = search.trim();
                        if (!frames) return [];
                        const filteredFrames = this.frameFilter.filterFrames(filters, frames);

                        const searchQuerySegments = searchQuery.toLowerCase().split(" ").filter(Boolean);
                        return filteredFrames.filter((f) =>
                            searchQuerySegments.every((queryPart) =>
                                f.brand.toLowerCase().includes(queryPart) ||
                                f.model.toLowerCase().includes(queryPart)
                                // un-comment below code to enable serach on color property
                                // || f.color.toLowerCase().includes(queryPart)
                            )
                        );
                    })
                );
                return source.pipe(
                    tap(frames => requestAnimationFrame(
                        () => { this.frameArrayLength$.next(frames.length); })),
                );
            })
        );

        this.frames$ = this.getFrames().pipe();

        this.favoriteCount$ = combineLatest([
            this._session.selectedSession$,
            this._frames.allFrames$,
        ]).pipe(
            map(([session, frames]) => {
                session.favoritedFrames = session?.favoritedFrames?.filter((f) => (frames?.findIndex(s => s.id === f.frameId) > -1));
                return session.favoritedFrames ? session.favoritedFrames.length : 0;
            })
        );

        this.favoritedFrames$ = this._session.selectedSession$.pipe(
            map((session) => session.favoritedFrames),
            startWith<FavoriteFrame[]>([])
        );

        this.filteredFramesCount$ = combineLatest([
            this._frames.frsSortedFrameCatalogue$,
            this.frameFilter.filtersForPreview$,
        ]).pipe(
            map(
                ([frames, filters]) => this.frameFilter.filterFrames(filters, frames).length
            )
        );

        this.priceFilterSubscription = this._frames.frsSortedFrameCatalogue$
            .pipe(
                filter((f) => f.length > 0),
                tap((frames) => {
                    const max = Math.max(...frames.map((f) => f.retailPrice));
                    this.debug.log(max);
                    this.changeHighestPriceValue(max);
                })
            )
            .subscribe();

            this.dmtFrames$ = this._frames.dmtRecommendedFrames$.pipe(
                map((fs) =>
                    fs.filter(
                        (f) =>
                            f.availabilityStatus ? parseInt(FrameAvailability[f.availabilityStatus]) != FrameAvailability.PERMANENTLY_NOT_AVAILABLE : true
                    )
                ),
                tap(_ => {  
                    if(this.jumpInDmtRecFlag== "true"){              
                        setTimeout(()=>{
                            if (!this.isScrollToAccessed && this.jumpScrollPosition)
                            {
                                this.virtualScrollerRecommendations.scrollToPosition(this.jumpScrollPosition);  
                                this.isScrollToAccessed = true;
                            }                     
                        },1000);
                    }
                })
            );

        this.showLogo = await this.loadEcpLogo();

        if(scrollToIndex && scrollToIndex > this.preloadFrames){         
          const intervalListener  =  setInterval(()=>{
                if(this.preloadFrames< scrollToIndex ){
                    this.isIntervalRunning = true;
                    this.preloadFrames = this.preloadFrames + 50;
                    this.framesToPreload$.next(this.preloadFrames);
                    this.isScrollToAccessed = true;
                }
                else
                {
                    this.isIntervalRunning = false;
                    window.clearInterval(intervalListener); 
                    this.isScrollToAccessed = false;
                }
            },350);                  
        }
        
    }



    private changeHighestPriceValue(price: number): void {
        this.frameFilter.highestPriceAvailable = price;
        const optionsCopy = Object.assign({}, this.sliderOptions);
        optionsCopy.ceil = this.frameFilter.highestPriceAvailable;
        this.sliderOptions = optionsCopy;
    }

    ngAfterViewInit() {
        this._frameWrapperContainer = this._frameWrapperHost.nativeElement;

        const s = this._session.selectedSession$.getValue();

        if (s) {
            this.gtm.add = {
                opticianId: s.opticianId,
                opticianName: s.opticianName,
            };
            this.gtm.push();
        }
    }

    ngOnDestroy() {
        if (this.priceFilterSubscription) {
            this.priceFilterSubscription.unsubscribe();
        }
    }

    public changeTrigger() {
        this.changeTrigger$.next('');
        this.cd.detectChanges();
    }

    public compareFrameChanged(event:any){
        this.latestComparedFrame = event;
    }

    public onFocusSearchBar(focused: boolean) {
        this.searchFocus$.next(focused);
    }



    scrollHandler(event) {
        this.removeElement('more-tooltip');
        this.removeElement('compare-tooltip');
        this.removeElement('favorite-tooltip');
    }

    removeElement(className:string) {
        const elements = document.getElementsByClassName(className);
        if (elements?.length > 0) {
            // elements[0].remove();
            this.renderer.removeChild(this.element.nativeElement, elements[0])
        }
    }

    public applyFilters() {
        this.frameCompareList = [];
        this.frameFilter.applyFilters(this.sessionId);
        this.drawerElement.close();
        this._frameWrapperContainer.scrollTo(0, 0);
    }

    public clearFilters() {
        this.frameFilter.clearFilters(this.sessionId);
        this.frameCompareList = [];
        this.drawerElement.close();
    }

    public priceInputChange(context: ChangeContext): void {
        const f = Object.assign(
            {},
            {
                ...this.frameFilter.filtersForPreview$.getValue(),
                ...(context.value != 0 ||
                    context.highValue != this.frameFilter.highestPriceAvailable
                    ? { price: [context.value, context.highValue] }
                    : { price: null }),
            }
        );
        this.frameFilter.selectedFilters = f;
        this.frameFilter.filtersForPreview$.next(f);
    }

    public get frames() {
        return null;
        // return this.vtoState.frameServiceInstance;
    }

    public closeGallery() {

        if (this.route.snapshot.queryParams.from == "viewer") {
            this.router.navigate([`viewer/${this.sessionId}`], {
                queryParams: {
                    from: "profile",
                    mode: this.route.snapshot.queryParams.mode,
                },
            });
        } else {
            this.router.navigate(['/profile']);
        }
    }

    public virtualScrollerTrackBy(index: number, frame: Frame): string {
        return frame.id;
    }
    public recVirtualScrollerTrackBy(index: number, frame: Frame): string {
        return frame.id;
    }

    public getFrames() {
        return combineLatest([this.frameSource$, this.framesToPreload$.pipe(distinctUntilChanged()), this._frames.favoritedFrames$]).pipe(
            map(([frames, framesToPreload, favoriteFrames]) => {
                if (this.favoritesSelected$.getValue()) {                 
                    return favoriteFrames;
                } else {                    
                    return frames.slice(0, framesToPreload);                    
                }                
            }),
            tap(_ => {                
                setTimeout(()=>{
                    if (!this.isScrollToAccessed && this.jumpInDmtRecFlag == "false" && this.jumpScrollPosition)
                    {
                        this.virtualScroller.scrollToPosition(this.jumpScrollPosition);  
                        this.isScrollToAccessed = true;
                    } 
                    if(!this.isIntervalRunning)
                        requestAnimationFrame(() => this.isLoading$.next(false))
                },1000);
            
            })
        );
    }

    public getColorDotGrid(variantsLength: number) {
        switch (variantsLength) {
            case 0:
                return "";
            case 1:
                return "s4 offset-s4";
            case 2:
                return "s6";
            case 3:
                return "s4";
            case 4:
                return "s3";
            default:
                return "s3";
        }
    }

    public toggleFavorites() {
        this.mode = this.mode == "favorite" ? "optician" : "favorite";
        this.favoritesSelected$.next(!this.favoritesSelected$.getValue());

        if (this.mode == "optician") {
            if (this.preloadFrames > this.frameArrayLength$.getValue()) {
                this.frameArrayLength$.next(this.preloadFrames);
                this.framesToPreload$.next(this.preloadFrames);
            }

            this.gtm.pageView("optician-inventory");
        } else {
            this.gtm.pageView("favorite-gallery");
        }
        this._frameWrapperContainer.scrollTo(0, 0);        
        this.clearFrameList();
    }

    public clearFrameList() {
        this.frameCompareList = [];
    }

    // public toggleCompareItem(frameId: string) {
    //     const i = this.frameCompareList.findIndex((id) => id === frameId);
    //     if (i !== -1) {
    //         this.frameCompareList.splice(i, 1);
    //         this.debug.log("removed item");
    //     } else if (this.frameCompareList.length < 2) {
    //         this.frameCompareList.push(frameId);
    //         this.debug.log("pushed item");
    //     }
    // }

    // Checks if a given frame ID is selected for comparison
    public isSelected(frameId: string) {
        return this.frameCompareList.find((frame) => frame.frameId === frameId);
    }

    public isCompareDisabled(frameId: string): boolean {
        return this.frameCompareList.length >= 2 && !this.isSelected(frameId);
    }

    public loadMore(event:IPageInfo) {        
       if (!(event.startIndex == 0 && event.scrollStartPosition > 0 && event.endIndex != this.lastScrollEndIndex)){ //dragging scroll bar returns incorrect endIndex, so workaround handling done here with loading icon for drag 
            this.lastScrollEndIndex = event.endIndex;
            if (event.endIndex < 0 || event.endIndex < this.framesToPreload$.getValue()-10 )
                return; 
        }
        else{                    
            this.lastScrollEndIndex = event.endIndex;
        }
           

        this.framesToPreload$.next(
            this.framesToPreload$.getValue() + this.preloadIncrement <
                this.frameArrayLength$.getValue()
                ? this.framesToPreload$.getValue() + this.preloadIncrement
                : this.frameArrayLength$.getValue()
        );
    }

    public updateFrameSelection(favoriteFrame: string) {
        if (
            this.toCompare.findIndex((element) => element === favoriteFrame) ===
            -1
        ) {
            this.toCompare.push(favoriteFrame);
        } else {
            this.toCompare.splice(
                this.toCompare.findIndex(
                    (element) => element === favoriteFrame
                ),
                1
            );
        }
    }

    public openCompareDialog() {
        if (this.frameCompareList.length < 2) return;

        const firstFrame = this.frameCompareList[0].frameId;
        const secondFrame = this.frameCompareList[1].frameId;

        const url = `/compare/${this.sessionId}/${firstFrame}/${secondFrame}`;
        let params = { from: "gallery", mode: this.mode,scrollPosition:null,endIndex:null,dmt:null};
        if (this.latestComparedFrame.scrollToPosition || this.latestComparedFrame.scrollToPosition  == "0" ){   
            params.scrollPosition = parseInt(this.latestComparedFrame.scrollToPosition);
            params.endIndex = this.latestComparedFrame.endIndex;
            if(this.latestComparedFrame.dmtFlag){
                params.dmt = true;
            }
        }

        if (this.frameCompareList[0].coatingId) params['leftCoating'] = this.frameCompareList[0].coatingId;
        if (this.frameCompareList[0].tintId) params['leftTint'] = this.frameCompareList[0].tintId;

        if (this.frameCompareList[1].coatingId) params['rightCoating'] = this.frameCompareList[1].coatingId;
        if (this.frameCompareList[1].tintId) params['rightTint'] = this.frameCompareList[1].tintId;

        this.router.navigate([url], {
            queryParams: params,
        });
    }

    public getColorThumbnail(frameId: string) {
        return this._datacache.getBinary(BinaryType.COLOR_THUMBNAIL,frameId);
    }

    public onSearch(searchterm: string) {
        this.frameSearch$.next(searchterm);
        this._frameWrapperContainer?.scrollTo(0, 0);
    }
    public async loadEcpLogo(): Promise<boolean> {
        const session = this._session.selectedSession$.getValue();
        if (session) {
            const b = await this._datacache.getBinary(BinaryType.ECP_LOGO,this._session.selectedSession$.getValue().opticianId,true);
            if (b) return true;
        }
        return false;
    }
    
    public backToTopClick(){
        setTimeout(()=>{    
            if(this.mode === "favorite" && this._frames.dmtRecommendedFramesCount$.getValue()>0) 
                this.virtualScrollerRecommendations.scrollToIndex(0); 
            else
                this.virtualScroller.scrollToIndex(0);
                       
        },500);
        
    }

    public switchListView(){
        this.virtualScroller?.invalidateAllCachedMeasurements();
        this.virtualScrollerRecommendations?.invalidateAllCachedMeasurements();
        this.listActive = !this.listActive;
        this.frameCompareList = [];
    }
}