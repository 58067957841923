export class FrameFilter {
    public type: string = null;
    public brand: Array<string> = null;
    public style: string = null;
    public shapeType: string = null;
    public rimType: string = null;
    public material: FrameMaterial[] = null;
    public price: Array<number> = null;
    public isColorful: boolean = null;
    public isDark: boolean = null;
    public isLight: boolean = null;
    public targetGroup: string = null;
}

export enum FrameMaterial {
    'WOOD',
    'PLASTIC',
    'METAL',
    'ALUMINIUM',
    'TITANIUM',
    'STAINLESS_STEEL',
    'CARBON',
    'ACETATE',
    'BUFFALO_HORN',
    'COMBINATION',
    'OTHERS'
};