<div matSnackBarLabel>
    <mat-icon class="cyan-text icon" vcldAutomationId="consr_vto_vcld-snackbar_info-on-click-of-last-frame_rbtn">{{ data?.icon }}</mat-icon>
    <div>
        <p class="bold">{{data?.heading | translate }}</p>
        <p class="quick-silver-text">
            {{ data?.message | translate : data?.messageParams }}
            <a matSnackBarAction *ngIf="data?.support" class="cyan-text pointer" (click)="openSupport()">
                {{"errors.support" | translate }}
            </a>
        </p>

    </div>
</div>
<button mat-icon-button matSnackBarAction (click)="dismiss()" class="platinum-text">
    <mat-icon *ngIf="data?.btnIcon">{{ data?.btnIcon }}</mat-icon>
    <p *ngIf="data?.btnText">{{ data?.btnText | translate }}</p>
</button>