import { AfterViewInit, Component, OnInit } from "@angular/core";
import Licenses from "./license.json";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { MatDialog } from "@angular/material/dialog";
import { ConsumerAppEnvironment as environment } from "visenvironment";
import { AboutDialogComponent } from "../../dialogs/about/about.dialog.component";
import { DebugService } from "../../services/debug.service";

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: "vis-license-page",
    templateUrl: "./license.page.component.html",
    styleUrls: ["./license.page.component.scss"],
})
export class LicensePageComponent implements AfterViewInit {
    private opened: Array<string> = [];

    constructor(
        private bottom: MatBottomSheet,
        private dialogHost: MatDialog,
        private debug: DebugService
    ) {}

    public licenses = Licenses;


    // Workaround for angular component issue #13870
    disableAnimation = true;
    
    ngAfterViewInit(): void {
        // timeout required to avoid the dreaded 'ExpressionChangedAfterItHasBeenCheckedError'
        setTimeout(() => (this.disableAnimation = false));
    }

    public get versionNumber() {
        return environment.version.split("+").shift();
    }

    public getCSS(id: string): string {
        if (this.opened.includes(id)) {
            return "selected";
        }

        return "";
    }

    public openPanel(id: string) {
        if (!this.opened.includes(id)) {
            this.opened.push(id);
        } else {
            this.debug.log(`the selected id (${id}) is already in list!`);
        }
    }

    public closePanel(id: string) {
        if (this.opened.includes(id)) {
            this.opened.splice(
                this.opened.findIndex((s) => s === id),
                1
            );
        } else {
            this.debug.log(`the selected id (${id}) is not in list!`);
        }
    }

    public openSupport() {
        window.open(environment.contact.form_stack, "_blank", "location=no");
        // this.bottom.open(ContactCardComponent, { data: { target: 'zeiss' } }).afterDismissed().toPromise().then(_ => {
        // });
    }

    public openAbout() {
        // Small Devices
        if (window.innerWidth < 993) {
            this.dialogHost.open<AboutDialogComponent>(AboutDialogComponent, {
                width: "99vw",
                autoFocus: false,
            });
        }
        // Medium Devices
        else if (window.innerWidth >= 993 && window.innerWidth < 1201) {
            this.dialogHost.open<AboutDialogComponent>(AboutDialogComponent, {
                width: "50vw",
                autoFocus: false,
            });
        }
        // Large Devces
        else if (window.innerWidth >= 1201) {
            this.dialogHost.open<AboutDialogComponent>(AboutDialogComponent, {
                width: "33vw",
                autoFocus: false,
            });
        }
    }
}
