<div class="filter-component" vcldAutomationId="consr_vto_vis-filter_filter-section_tab">
    <div class="top">
        <h1 class="headline">{{ "pages.gallery.filters.filter" | translate }}</h1>
        <mat-icon vcldAutomationId="consr_vis-filter_close-filter_icon" class="close-filter" (click)="close()">close</mat-icon>
    </div>
    <div class="filter-sections">
        <div class="section">
            <p class="headline-section">{{'pages.gallery.filters.spectacle_type' | translate}}</p>
            <div class="filter-item">
                <vis-filter-tile vcldAutomationId="consr_vis-filter_spectacle-type_icon" *ngFor="let filterItem of filterTypeList" [filterTileText]="filterItem.filterText"
                    [filterIcon]="filterItem.filterIcon"
                    [selected]="frameFilter.isFilterSelected('type', filterItem.filterType)"
                    (click)="frameFilter.selectFilter('type', filterItem.filterType)">
                </vis-filter-tile>
            </div>
        </div>
        <div class="section">
            <p class="headline-section">{{'pages.gallery.filters.target_group' | translate}}</p>
            <div class="filter-item">
                <vis-filter-tile vcldAutomationId="consr_vis-filter_target-group_icon" *ngFor="let filterItem of filterTargetList" [filterTileText]="filterItem.filterText"
                    [filterIcon]="filterItem.filterIcon"
                    [selected]="frameFilter.isFilterSelected('targetGroup', filterItem.filterType)"
                    (click)="frameFilter.selectFilter('targetGroup', filterItem.filterType)">
                </vis-filter-tile>
            </div>
        </div>
        <div class="section">
            <p class="headline-section">{{ "pages.gallery.filters.frameshape" | translate }}</p>
            <div class="filter-item">
                <vis-filter-tile vcldAutomationId="consr_vis-filter_frame-shape_icon" *ngFor="let filterItem of filterShapeList" [filterTileText]="filterItem.filterText"
                    [filterIcon]="filterItem.filterIcon"
                    [selected]="frameFilter.isFilterSelected('shapeType', filterItem.filterType)"
                    (click)="frameFilter.selectFilter('shapeType', filterItem.filterType)">
                </vis-filter-tile>
            </div>
        </div>
        <div class="section">
            <p class="headline-section">{{ "pages.gallery.filters.rimtype" | translate }}</p>
            <div class="filter-item">
                <vis-filter-tile vcldAutomationId="consr_vis-filter_rim-type_icon" *ngFor="let filterItem of filterRimList" [filterTileText]="filterItem.filterText"
                    [filterIcon]="filterItem.filterIcon"
                    [selected]="frameFilter.isFilterSelected('rimType', filterItem.filterType)"
                    (click)="frameFilter.selectFilter('rimType', filterItem.filterType)">
                </vis-filter-tile>
            </div>
        </div>
        <div class="section">
            <p class="headline-section">{{ "pages.gallery.filters.color" | translate }}</p>
            <div class="filter-item">
                <vis-filter-tile vcldAutomationId="consr_vis-filter_color_icon" *ngFor="let filterItem of filterColorList" [filterTileText]="filterItem.filterText"
                    [filterIcon]="filterItem.filterIcon"
                    [selected]="frameFilter.isColorFilterSelected(filterItem.filterType)"
                    (click)="frameFilter.selectColorFilter(filterItem.filterType)">
                </vis-filter-tile>
            </div>
        </div>
        <div class="section">
            <p class="headline-section">{{ "pages.gallery.filters.lifestyle" | translate }}</p>
            <div class="filter-item">
                <vis-filter-tile vcldAutomationId="consr_vis-filter_lifestyle_icon" *ngFor="let filterItem of filterLifestyleList" [filterTileText]="filterItem.filterText"
                    [filterIcon]="filterItem.filterIcon"
                    [selected]="frameFilter.isFilterSelected('style', filterItem.filterType)"
                    (click)="frameFilter.selectFilter('style', filterItem.filterType)">
                </vis-filter-tile>
            </div>
        </div>
        <div class="section">
            <p class="headline-section">{{ "pages.gallery.filters.material" | translate }}</p>
            <div class="filter-item">
                <vis-filter-tile vcldAutomationId="consr_vis-filter_material_icon" *ngFor="let filterItem of filterMaterialList" [filterTileText]="filterItem.filterText"
                    [filterIcon]="filterItem.filterIcon"
                    [selected]="frameFilter.isMaterialSelected(filterItem.filterType)"
                    (click)="frameFilter.selectFilter('material', filterItem.filterType)">
                </vis-filter-tile>
            </div>
        </div>
        <div class="section">
            <p class="headline-section">{{ "pages.gallery.filters.brands" | translate }}</p>
            <div class="brands">
                <mat-checkbox vcldAutomationId="consr_vis-filter_brand_chkbox" color="primary" *ngFor="let brand of _frames.allBrands$ | async"
                    [checked]="frameFilter.isBrandSelected(brand)" (change)="frameFilter.selectFilter('brand', brand)">
                    <div class="container" vcldTooltip>{{brand}}</div>
                </mat-checkbox>
            </div>
        </div>
        <div class="section" *ngIf="(_viewer.ecpSettings$ | async)?.pricingEnabled && ((frameFilter.priceFilterMaxValue$ | async) > (frameFilter.priceFilterMinValue$ | async))">
            <p class="headline-section">{{ "pages.gallery.filters.price" | translate }}</p>
            <app-vcld-slider class="vertical-slider" [options]="sliderOptions" [value]="frameFilter.minimumPriceValue" [highValue]="frameFilter.maximumPriceValue" [direction]="'horizontal'" (userValueChange)="priceInputChange($event.event)"></app-vcld-slider>
        </div>

    </div>
</div>