import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DebugService } from '../../services/debug.service';

export enum GTMLayer {
  pagePool = 'pagePool',
  pageIdentifier = 'pageIdentifier',
  pageCountry = 'pageCountry',
  pageArea = 'pageArea',
  pageLanguage = 'pageLanguage',
  opticianId = 'opticianId',
  opticianName = 'opticianName'
}


export enum GTMCustomEvents {
  landing_login = 'landing_login',
  profile_delete_avatar_confirm = 'profile_delete_avatar_confirm',
  hamburger_logout = 'hamburger_logout',
  viewer_frame_only = 'viewer_frame_only',
  viewer_color_options = 'viewer_color_options',
  viewer_size_options = 'viewer_size_options',
  viewer_nose_slider = 'viewer_nose_slider',
  viewer_favourite_marker_bottom = 'viewer_favourite_marker_bottom',
  viewer_slider_favourites = 'viewer_slider_favourites',
  viewer_slider_frames = 'viewer_slider_frames',
  viewer_slider_lenses = 'viewer_slider_lenses',
  viewer_lenses_select = 'viewer_lenses_select',
  viewer_coatings_select = 'viewer_coatings_select',
  viewer_tints_select = 'viewer_tints_select',
  contact_ecp_submit = 'contact_ecp_submit',
  contant_support_submit = 'contant_support_submit',
  gallery_filterbar = 'gallery_filterbar',
  gallery_filter_show = 'gallery_filter_show',
  compare_frame_only = 'compare_frame_only',
  compare_nose_slider = 'compare_nose_slider'
}

export const GTMEventKey = 'GACustomEvent';


export type GTMDefaultLayer = {
  [key in GTMLayer]: string | undefined
};

export type GTMCustomLayer = {
  [key: string]: string
};

@Injectable({
  providedIn: 'root'
})
export class GoogleTagManagerService {

  // eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match
  private _defaultSet: GTMDefaultLayer;

  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
  private _constantSet: GTMDefaultLayer;

  constructor(private router: Router, private debug: DebugService) {
    this._defaultSet = this.EmptyDefaultLayer;
    this._constantSet = this.ConstantDefaultLayer;
  }

  public set add(value: GTMCustomLayer) {
    Object.keys(GTMLayer).map(key => GTMLayer[key]).forEach(key => {
      this._defaultSet[key] = value[key] || this._constantSet[key];
    });
  }

  public pageView(overridePath: string) {
    (window as any).dataLayer.push({
      event: 'pageview',
      page: overridePath || this.router.url,
      title: overridePath || this.router.url,
    });
  }

  public pushEvent(name: string) {
    (window as any).dataLayer.push({ event: GTMEventKey, eventAction: name });
  }

  public push(): void {
    (window as any)?.dataLayer?.push(this._defaultSet);
    this.debug.log('push dataLayer: ', this._defaultSet);
  }

  private get EmptyDefaultLayer(): GTMDefaultLayer {
    return {
      [GTMLayer.pageArea]: undefined,
      [GTMLayer.pageCountry]: undefined,
      [GTMLayer.pageIdentifier]: undefined,
      [GTMLayer.pageLanguage]: undefined,
      [GTMLayer.pagePool]: undefined,
      [GTMLayer.opticianId]: undefined,
      [GTMLayer.opticianName]: undefined
    };
  }

  private get ConstantDefaultLayer(): GTMDefaultLayer {
    const lang = localStorage.getItem('vcldlang') ? localStorage.getItem('vcldlang') : 'en';
    return {
      [GTMLayer.pageArea]: 'por',
      [GTMLayer.pageCountry]: 'int',
      [GTMLayer.pageIdentifier]: 'vcloud',
      [GTMLayer.pageLanguage]: lang,
      [GTMLayer.pagePool]: 'vis',
      [GTMLayer.opticianId]: 'undefined',
      [GTMLayer.opticianName]: 'undefined'
    };
  }

}
