<div class="dialog-header">
    <mat-icon class="icon"> warning</mat-icon>
    <p mat-dialog-title class="topline">{{ "components.dialogs.delete.delete_avatar" | translate }}</p>
</div>

<mat-dialog-content>
    <p class="subline">{{ "components.dialogs.delete.confirm_deletion" | translate }}</p>
</mat-dialog-content>

<mat-dialog-actions class="row">
    <button mat-button color="primary" class="col s6 m3" (click)="onNoClick()"
        cdkFocusInitial>
        {{ "components.buttons.cancel" | translate }}
    </button>
    <button mat-flat-button color="primary" (click)="onYesClick()" class="col s6 m3">
        {{ "components.buttons.delete" | translate }}
    </button>
</mat-dialog-actions>