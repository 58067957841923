import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ConsumerAppEnvironment as environment } from 'visenvironment';
import { Language } from '../configs/constant.flags';

@Injectable({providedIn: 'root'})
export class LanguageService {

    public availableLanguages$: BehaviorSubject<Language[]> = new BehaviorSubject<Language[]>([]);

    constructor(private http: HttpClient) { }

    // fetch languages and next them in a BehaviorSubject
    public async fetchLanguages() {
        const uri = environment.connectivity.translation_registry;
        const languages = await this.http.get<Language[]>(uri).toPromise();
        this.availableLanguages$.next(languages);
    }

    public get getLanguages() {
        let availableLanguages: Language[] = null;
        this.availableLanguages$.subscribe((data) => {
            availableLanguages = data;
        });
        return availableLanguages;
    }
}