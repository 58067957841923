import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of, ReplaySubject } from 'rxjs';
import { catchError, map, tap, switchMap } from 'rxjs/operators';
import { DebugService } from '../../services/debug.service';
import { AvatarCreationSession, FavoriteFrame } from '../models/avatarcreationsession';
import { Frame } from '../models/frame.model';
import { FrameProbability } from '../models/frameprobability';
import { DataService, DataType,BinaryType } from './data.service';
import { SessionService } from './session.service';
import { TintsCoatingsService } from './tints-coatings.service';
import { ConsumerAppEnvironment as environment } from 'visenvironment';
import { FrameSelectionMail } from '../models/frame-selection-mail.model';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { OAuthService } from 'angular-oauth2-oidc';
import { FrameVariants } from '../models/frame.variants.model';
import { LensSettings } from '@vision/webviewer/lib/widget';
import { ShaderResponse } from '../models/shaderResponse';
import { FeatureFlagsService } from '../../services/featureFlag.service';

@Injectable({
  providedIn: 'root'
})
export class FrameService {
    public readonly defaultCustomerNo = "0000620681";
  public allFrames$ : BehaviorSubject<Frame[]> = new BehaviorSubject<Frame[]>(null);
  public allBrands$ : BehaviorSubject<string[]> = new BehaviorSubject<string[]>(null);
  public frsSortedFrameCatalogue$: ReplaySubject<Frame[]> = new ReplaySubject<Frame[]>(1); 
  public dmtRecommendedFrames$: ReplaySubject<Frame[]> = new ReplaySubject<Frame[]>(1); 
  public dmtRecommendedFramesCount$: BehaviorSubject<number> = new BehaviorSubject<number>(null); 
  public favoritedFrames$ : ReplaySubject<Frame[]> = new ReplaySubject<Frame[]>(1);
  public selectedFrame$: ReplaySubject<Frame> = new ReplaySubject<Frame>(1);
  public listFavFramesSession:FavoriteFrame[]=[];

  constructor (
        private _debug: DebugService,
    private _datacache:DataService,
    private _session:SessionService,
    private _tinstCoatings:TintsCoatingsService,
        private _http: HttpClient,
        private _oauth: OAuthService,
        private features: FeatureFlagsService
    ) {}

  public async initAllFrames(customerNo:string,) {
    let frames:Frame[] = await this._datacache.getData(DataType.FRAME_CATALOGUE_OPTICIAN,customerNo);
    let allBrands = new Set(frames?.map((f) => f.brand))
    this.allBrands$.next([...allBrands].sort((a:string, b:string) => {
                return a?.toLowerCase().localeCompare(b?.toLowerCase());
    }));
        this.allFrames$.next(frames);
    }

  public async initRecommendedFrames(sessionId:string): Promise<Frame[] | void> {
    let frames:Frame[] = this.allFrames$.getValue();
    let probs: FrameProbability[]= await this._datacache.getData(DataType.FRAME_PROBABILITY,sessionId);
        if (probs.length === 0) {
            // In case there are no recommendations, we just return the full frame catalogue
            this.frsSortedFrameCatalogue$.next(frames);
            return frames ?? [];
        }
    let sorted :Frame[]= [];
        frames.forEach((a) => {
            const iA = probs.findIndex((prob) => prob.id === a.id);
            if (iA === -1) {
                sorted.push(a);
            } else {
                sorted.splice(iA, 0, a);
            }
        });
        sorted.forEach((frame) => {
            // Make sure that a empty price is labeld with "0"
            if (!frame.retailPrice || frame.retailPrice == 0) {
                frame.retailPrice = 0;
            }
        });
        this.frsSortedFrameCatalogue$.next(sorted);
    }

    public async initCampaignRecommendations() {
    let frames:Frame[] = this.allFrames$.getValue();
    let selectedSession = this._session.selectedSession$.getValue();
    const filteredFrames = frames?.filter((f) => selectedSession?.recommendedFrames?.includes(f.id));
    const filteredFramesCount = filteredFrames == null ? 0 : filteredFrames.length
        this.dmtRecommendedFrames$.next(filteredFrames);
        this.dmtRecommendedFramesCount$.next(filteredFramesCount);
    }

    public async initFavoritedFrames() {
    let frames:Frame[] = this.allFrames$.getValue();
    let selectedSession= this._session.selectedSession$.getValue();
    let tintsAndCoatingCatalogue = this._tinstCoatings.tintsCoatingsCatalogue$.getValue();

      if (tintsAndCoatingCatalogue && selectedSession ) {
          if (frames?.length > 0 && selectedSession?.favoritedFrames && selectedSession?.favoritedFrames?.length > 0) {
            selectedSession.favoritedFrames = selectedSession.favoritedFrames.filter((f) => (frames?.findIndex(s => s.id === f.frameId) > -1));
            }

          this.favoritedFrames$.next(this._tinstCoatings.updateWithTintAndCoating(frames, selectedSession));       
    }
    else
    this.favoritedFrames$.next([]);
    }
    
    public getFrameThumbnail(frameId: string, customerNumber?: string) {
        const customerNo = customerNumber
            ? customerNumber
            : this.defaultCustomerNo;
   return this._datacache.getBinary(BinaryType.FRAME_THUMBNAIL,frameId, true, { customerNo });
    }

    public sendFrameSelection(frameSelection: FrameSelectionMail) {
        this._debug.log(
            `[Virtual Try On Service] Sending FrameSelection => Pending...`,
            frameSelection
        );
        const url = environment.connectivity.frame_selection_endpoint;
        return this._http.post(url, frameSelection, {
            headers: { Authorization: `Bearer ${this._oauth.getIdToken()}` },
        });
    }

    public getVariantsForFrame(frameId: string, customerNumber?: string) {
        const customerNo = customerNumber
            ? customerNumber
            : this.defaultCustomerNo;
        const url = `${environment.connectivity.frameVariants}/${customerNo}/${frameId}`;

        return this._http.get<FrameVariants>(url, {
            headers: { Authorization: `Bearer ${this._oauth.getIdToken()}` },
        });
    }

    public sunglassShader(frameId: string): Observable<LensSettings> {
        let isCDNEnabled: boolean =
            this.features.isFeatureFlagEnabled("CDNEnabled");
        let defaultLensURL = isCDNEnabled
            ? `${environment.connectivity.gfdbCDNConnectionEndpoint}/${frameId}/COMMON/DEFAULTLENS.JSON`
            : `${environment.connectivity.esbBaseUrl}/frame/620681/${frameId}/DefaultLens.json`;
        return this._http
            .get<ShaderResponse>(defaultLensURL, {
                headers: {
                    Authorization: `Bearer ${this._oauth.getIdToken()}`,
                },
            })
            .pipe(
                catchError((e) => {
                    return of(null);
                }),
                switchMap((res: ShaderResponse) => {
                    if (res == null && isCDNEnabled) {
                        return this._http
                            .get<ShaderResponse>(
                                `${environment.connectivity.esbBaseUrl}/frame/620681/${frameId}/DefaultLens.json`,
                                {
                                    headers: {
                                        Authorization: `Bearer ${this._oauth.getIdToken()}`,
                                    },
                                }
                            )
                            .pipe(
                                map((res) => {
                                    return this.formatShaderResponse(res);
                                })
                            );
                    } else if (res == null) {
                        return null;
                    } else {
                        return of(this.formatShaderResponse(res));
                    }
                })
            );
    }
    public formatShaderResponse(response: ShaderResponse): LensSettings {
        return {
            antiReflectCoatingPower: response.coating.BlendSpeed,
            antiReflectCoatingReflectivity: {
                r: response.coating.Reflectivity.R,
                g: response.coating.Reflectivity.G,
                b: response.coating.Reflectivity.B,
            },
            antiReflectCoatingReflectivity90: {
                r: response.coating.Reflectivity90.R,
                g: response.coating.Reflectivity90.G,
                b: response.coating.Reflectivity90.B,
            },
            isMirror: response.coating.IsMirrored,
            lensWeight: {
                r: response.tint.BottomColor.R,
                g: response.tint.BottomColor.G,
                b: response.tint.BottomColor.B,
            },
            lensWeightTop: {
                r: response.tint.TopColor.R,
                g: response.tint.TopColor.G,
                b: response.tint.TopColor.B,
            },
            opacity: response.tint.BottomColor.HighestAbsorption,
            opacityTop: response.tint.TopColor.HighestAbsorption,
        };
    }

    public getFrameTransformations(frameId: string, sessionId: string) {
        const uri = `${environment.connectivity.fpsEndpoint}`;
        return this._http.get<any>(uri, {
            headers: { Authorization: "Bearer " + this._oauth.getIdToken() },
          params: { frameId, sessionId, format: 'vcld' },
        });
    }

  public updateSessionFavorites(frames: FavoriteFrame[], selectedSession:string,favFrame:FavoriteFrame[]) {
        this._debug.log("[SessionSerivce] updating the session!!!!!!!");
        const uri = `${environment.connectivity.favoriteEndpoint}/${selectedSession}`;
        return this._http
            .put(uri, frames, {
            headers: { Authorization: `Bearer ${this._oauth.getIdToken()}` },
            })
            .pipe(
                map(async () => {
                const cacheEntry = this._datacache.invalidateCacheEntry(`${environment.connectivity.avatarSessionEndpoint}`,"SESSIONS");
                const updatedSession = <AvatarCreationSession[]> await this._datacache.getData(DataType.SESSIONS,`${environment.connectivity.avatarSessionEndpoint}`,{headers:{'x-re-cache': 'false'}});

                    const index = updatedSession.findIndex(
                        (s) => s.id === selectedSession
                    );
                    if (index !== -1) {
                        updatedSession[index].favoritedFrames = frames;
                        this._session.sessionsList$.next(updatedSession);
                    this._session.selectedSession$.next(updatedSession[index]);
                        this.initFavoritedFrames();                        
                        this.listFavFramesSession = this.listFavFramesSession.filter(
                            function (item) {
                                return favFrame.findIndex(x=>x.frameId=item.frameId) < 0;
                            }
                        );
                        return updatedSession[index].favoritedFrames;
                    }
                 this.listFavFramesSession = this.listFavFramesSession.filter(
                     function (item) {
                         return favFrame.findIndex(x=>x.frameId=item.frameId) < 0;
                     }
                 );
                return [];
                })
            );
    }

    public removeRecommendation(frameId: string, selectedSession:string) {
        const uri = `${environment.connectivity.recommendationsEndpoint}/${selectedSession}/${frameId}`;
        return this._http
            .delete(uri, {
              headers: { Authorization: `Bearer ${this._oauth.getIdToken()}` },
            })
            .pipe(
                tap(async () => {
                  const cacheEntry = this._datacache.invalidateCacheEntry(`${environment.connectivity.avatarSessionEndpoint}`,"SESSIONS");
                  const updatedSession = <AvatarCreationSession[]> await this._datacache.getData(DataType.SESSIONS,`${environment.connectivity.avatarSessionEndpoint}`, {headers:{'x-re-cache': 'false'}});
                    const index = updatedSession.findIndex(
                        (s) => s.id === selectedSession
                    );
                    if (index !== -1) {
                        this._session.sessionsList$.next(updatedSession);
                      this._session.selectedSession$.next(updatedSession[index]);
                        this.initCampaignRecommendations();
                    }
                    return [];
                })
            );
    }
}




